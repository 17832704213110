@charset "UTF-8";
.dropdown-input {
  width: 15rem;
}

.dropdown.selection {
  position: relative;
  width: 100%;
}
.dropdown.selection.active > .text {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown.selection.active > .icon {
  top: 0.6rem;
}
.dropdown.selection.active > .icon:before {
  content: "▲";
}
.dropdown.selection.active .menu {
  z-index: 1;
}
.dropdown.selection:not(.active):hover {
  opacity: 0.8;
}
.dropdown.selection:not(.active) .menu {
  display: none;
}
.dropdown.selection:not(.active) > .icon {
  top: 0.8rem;
}
.dropdown.selection:not(.active) > .icon:before {
  content: "▼";
}
.dropdown.selection > .text, .dropdown.selection > .icon {
  cursor: pointer;
}
.dropdown.selection > .text {
  padding: 0.7rem 1rem;
  padding-right: 2rem;
  border-radius: 0px;
}
.dropdown.selection .text {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown.selection > .icon {
  font-style: normal;
  position: absolute;
  right: 0.7rem;
  font-size: 0.7em;
  opacity: 0.6;
}
.dropdown.selection .menu {
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown.selection .menu .item:not(.active) {
  cursor: pointer;
}
.dropdown.selection .menu .item .text {
  padding: 0.7rem 1rem;
}

.theme-light .dropdown.selection.active > .text, .theme-light .dropdown.selection.active .menu {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
}
.theme-light .dropdown.selection > .text, .theme-light .dropdown.selection .menu {
  background-color: #F5F5F5;
}
.theme-light .dropdown.selection > .text {
  border: 0px solid #ddd;
}
.theme-light .dropdown.selection .menu {
  border: 0px solid #ddd;
  border-top: none;
}
.theme-light .dropdown.selection .menu .item.active {
  background-color: rgba(0, 0, 0, 0.05);
}
.theme-light .dropdown.selection .menu .item:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.025);
}