.page-section {
  position: relative;
  transition: opacity 0.6s;
}
.page-section.layout-text {
  opacity: 0.75;
}
.page-section .page-section-logo-text {
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 3.5rem;
  transition: opacity 1s;
}
.page-section .page-section-logo-text .logo-page-title {
  margin-left: 0.25em;
  opacity: 0.6;
}
.page-section .press .press-page-title, .page-section .press .awards-page-title, .page-section .awards .press-page-title, .page-section .awards .awards-page-title {
  letter-spacing: 3px;
  font-size: 2rem;
  text-transform: uppercase;
}
.page-section .press .press-page-footer .email, .page-section .press .awards-page-footer .email, .page-section .awards .press-page-footer .email, .page-section .awards .awards-page-footer .email {
  line-height: 1.6;
  color: rgb(119, 152, 173);
}
.page-section .press .press-page-footer .email:hover, .page-section .press .awards-page-footer .email:hover, .page-section .awards .press-page-footer .email:hover, .page-section .awards .awards-page-footer .email:hover {
  color: rgb(80, 112, 133);
}
.page-section .project-stories .tiles-container, .page-section .showrooms .tiles-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.page-section .project-stories .tiles-container .tile, .page-section .showrooms .tiles-container .tile {
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}
.page-section .project-stories .tiles-container .tile:not(:last-child), .page-section .showrooms .tiles-container .tile:not(:last-child) {
  margin-bottom: 2rem;
}
.page-section .project-stories .tiles-container .tile .tile-title, .page-section .showrooms .tiles-container .tile .tile-title {
  margin: auto;
  color: #FFF;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.page-section .project-stories .tiles-container .tile .tile-title > *, .page-section .showrooms .tiles-container .tile .tile-title > * {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.page-section .project-stories .tiles-container .tile .tile-title .tile-title-state, .page-section .project-stories .tiles-container .tile .tile-title .tile-title-coming-soon, .page-section .showrooms .tiles-container .tile .tile-title .tile-title-state, .page-section .showrooms .tiles-container .tile .tile-title .tile-title-coming-soon {
  font-size: 50%;
  opacity: 0.7;
}
.page-section .project-stories .tiles-container .tile .tile-image-container, .page-section .showrooms .tiles-container .tile .tile-image-container {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
.page-section .project-stories .tiles-container .tile .tile-image-container .tile-image, .page-section .showrooms .tiles-container .tile .tile-image-container .tile-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: filter ease 0.5s;
}
.page-section:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
@media print, (min-width: 801px) {
  .page-section:not(.heightAuto) {
    height: 100%;
    min-height: 48rem;
  }
  .page-section:not(:first-child).layout-text-left-grid-right .page-section-logo-text {
    display: none;
  }
  .page-section.text-style-branding .page-section-text .text-content {
    max-width: 25rem;
    font-size: 1rem;
  }
  .page-section.text-style-details:not(.layout-text):not(.layout-media-top-text-bottom) .page-section-text .text-content {
    max-width: 25rem;
    padding-left: 2rem;
  }
  .page-section.text-style-standard:not(.layout-text):not(.layout-media-top-text-bottom) .page-section-text {
    font-size: 1.8rem;
  }
  .page-section.text-style-standard:not(.layout-text):not(.layout-media-top-text-bottom) .page-section-text .text-content {
    padding-left: 2rem;
  }
  .page-section.heightAuto.layout-text {
    padding-bottom: 10rem;
  }
  .page-section:not(.heightAuto).layout-text {
    min-height: 100%;
  }
  .page-section.layout-text {
    padding: 2rem;
    height: auto;
  }
  .page-section.layout-text:not(.hideLogo):first-child {
    padding-bottom: 7rem;
  }
  .page-section.layout-text.hideLogo:first-child {
    padding-bottom: 2rem;
  }
  .page-section.layout-text.hideLogo .page-section-logo-text {
    display: none;
  }
  .page-section.layout-text .page-section-text {
    font-size: 1.8rem;
    height: 100%;
  }
  .page-section.layout-text .page-section-text .text-item-link {
    font-size: 0.8rem;
  }
  .page-section.layout-text-left .page-section-text {
    height: 100%;
    width: 50%;
  }
  .page-section.layout-text-left-media-right .page-section-text {
    padding-right: 4rem;
  }
  .page-section.layout-text-left-media-right .media-container .page-section-medium:not(.orientation-vertical) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .page-section.layout-text-left-media-right .vertical-split:not(.double-media) .page-section-medium.orientation-square .medium-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
  }
  .page-section.layout-text-left-media-right .vertical-split:not(.double-media) .page-section-medium.orientation-horizontal .medium-image {
    height: auto;
    background-image: none !important;
  }
  .page-section.layout-text-left-media-right .vertical-split:not(.double-media) .page-section-medium.orientation-horizontal .medium-image img {
    opacity: 1;
    height: auto;
    position: relative;
  }
  .page-section.layout-text-left-grid-right .page-section-text {
    padding-right: 4rem;
  }
  .page-section.layout-text-left-grid-right .page-section-grid {
    padding-left: 0;
  }
  .page-section.layout-media-top-text-bottom .horizontal-flex-split {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .page-section.layout-media-top-text-bottom .horizontal-flex-split .page-section-medium {
    height: auto;
    flex: 1;
  }
  .page-section.layout-media-top-text-bottom .horizontal-flex-split .page-section-text {
    padding-left: 2rem;
  }
  .page-section.layout-media-top-text-bottom .horizontal-flex-split .page-section-text .text-content {
    font-size: 1rem;
  }
  .page-section.layout-media {
    padding-top: 2rem;
  }
  .page-section.layout-media video {
    object-fit: cover;
  }
  .page-section.layout-accordion-with-media {
    min-height: 45rem;
  }
  .page-section.layout-accordion-with-media .accordion-container {
    display: flex;
    position: relative;
    align-items: flex-start;
    padding: 4rem;
  }
  .page-section.layout-accordion-with-media .accordion-container .accordion {
    width: 25rem;
    margin-bottom: 4rem;
  }
  .page-section.layout-accordion-with-media .media-container {
    height: 100vh !important;
    min-height: 45rem;
  }
  .page-section.layout-accordion-with-media .media-container .page-section-medium.orientation-square .medium-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
  }
  .page-section.layout-accordion-with-media .media-container .page-section-medium.orientation-horizontal .medium-image {
    height: auto;
    background-image: none !important;
  }
  .page-section.layout-accordion-with-media .media-container .page-section-medium.orientation-horizontal .medium-image img {
    opacity: 1;
    height: auto;
    position: relative;
  }
  .page-section.layout-double-media {
    padding-top: 2rem;
  }
  .page-section.layout-double-media .media-container.left .medium-image {
    background-position: right center !important;
  }
  .page-section.layout-double-media .media-container.right {
    margin-left: 1px;
  }
  .page-section.layout-double-media .media-container.right .medium-image {
    background-position: left center !important;
  }
  .page-section.layout-double-media .page-section-medium.orientation-square .medium-image, .page-section.layout-double-media .page-section-medium.orientation-vertical .medium-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
  }
  .page-section .text-item-link {
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
  }
  .page-section .page-section-logo-text {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
  .page-section .long-paragraph {
    font-size: 1rem;
    width: 75%;
  }
  .page-section .vertical-split {
    height: 100%;
    display: flex;
  }
  .page-section .vertical-split .page-section-logo-text {
    padding-left: 2rem;
  }
  .page-section .vertical-split .page-section-text, .page-section .vertical-split .media-container, .page-section .vertical-split .page-section-grid, .page-section .vertical-split .accordion-container {
    height: 100%;
    width: 50%;
  }
  .page-section .press .press-page-title, .page-section .press .press-page-footer,
  .page-section .press .awards-page-title, .page-section .press .awards-page-footer, .page-section .awards .press-page-title, .page-section .awards .press-page-footer,
  .page-section .awards .awards-page-title, .page-section .awards .awards-page-footer {
    padding: 2rem 2rem;
  }
  .page-section .press .press-quotes, .page-section .press .awards, .page-section .awards .press-quotes, .page-section .awards .awards {
    margin-bottom: 4rem;
  }
  .page-section .press .press-quotes .page-section-text, .page-section .press .awards .page-section-text, .page-section .awards .press-quotes .page-section-text, .page-section .awards .awards .page-section-text {
    padding: 2rem 2rem;
    padding-right: 10vw;
    width: 50%;
    display: inline-block;
  }
  .page-section .project-stories .tiles-container, .page-section .showrooms .tiles-container {
    padding: 0 2rem;
  }
  .page-section .project-stories .tiles-container .tile, .page-section .showrooms .tiles-container .tile {
    height: 100%;
    min-height: 48rem;
  }
  .page-section .project-stories .tiles-container .tile:hover, .page-section .showrooms .tiles-container .tile:hover {
    cursor: pointer;
  }
  .page-section .project-stories .tiles-container .tile:hover .tile-title, .page-section .showrooms .tiles-container .tile:hover .tile-title {
    opacity: 0.85;
  }
  .page-section .project-stories .tiles-container .tile:hover .tile-image-container .tile-image, .page-section .showrooms .tiles-container .tile:hover .tile-image-container .tile-image {
    filter: brightness(75%) !important;
  }
  .page-section .project-stories .tiles-container .tile .tile-title, .page-section .showrooms .tiles-container .tile .tile-title {
    font-size: 5.5vw;
    letter-spacing: 0.4vw;
    padding: 0 4rem;
  }
  .page-section .project-stories .tiles-container .tile .tile-image-container .tile-image, .page-section .showrooms .tiles-container .tile .tile-image-container .tile-image {
    filter: brightness(50%);
  }
}
@media only screen and (max-width: 800px) {
  .page-section {
    padding: 1rem;
  }
  .page-section:first-child:not(.layout-media):not(.layout-media-top-text-bottom):not(.layout-text) {
    min-height: 100%;
  }
  .page-section:not(:first-child) .page-section-logo-text {
    display: none;
  }
  .page-section.text-style-branding .page-section-text .text-content {
    font-size: 1rem;
  }
  .page-section.text-style-details .page-section-text .text-content .long-paragraph {
    font-size: 1rem;
  }
  .page-section.text-style-details .page-section-text .text-content .text-item-link {
    font-size: 0.8rem;
  }
  .page-section.layout-text-left-media-right .vertical-split, .page-section.layout-accordion-with-media .vertical-split {
    display: flex;
    flex-direction: column-reverse;
  }
  .page-section.layout-accordion-with-media .page-section-logo-text {
    display: none;
  }
  .page-section.layout-accordion-with-media .accordion {
    margin-top: 1rem;
  }
  .page-section.layout-text:first-child {
    padding-bottom: 5rem;
  }
  .page-section.layout-text.hideLogo:first-child {
    padding-bottom: 2rem;
  }
  .page-section.layout-text.hideLogo .page-section-logo-text {
    display: none;
  }
  .page-section.layout-text .page-section-text {
    font-size: 1.8rem;
    height: 100%;
  }
}
@media only screen and (max-width: 800px) and (max-device-width: 1024px) {
  .page-section.layout-text .page-section-text {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 800px) {
  .page-section:not(.layout-text) .page-section-logo-text {
    position: relative;
    bottom: 0px;
    margin-top: 1rem;
  }
  .page-section .page-section-medium {
    margin-bottom: 1px;
  }
  .page-section .page-section-text, .page-section .page-section-medium {
    display: block;
    width: 100%;
  }
  .page-section .press .press-page-title, .page-section .press .press-page-footer,
  .page-section .press .awards-page-title, .page-section .press .awards-page-footer, .page-section .awards .press-page-title, .page-section .awards .press-page-footer,
  .page-section .awards .awards-page-title, .page-section .awards .awards-page-footer {
    margin-bottom: 2rem;
  }
  .page-section .press .press-quotes, .page-section .press .awards, .page-section .awards .press-quotes, .page-section .awards .awards {
    margin-bottom: 4rem;
  }
  .page-section .press .press-quotes .page-section-text, .page-section .press .awards .page-section-text, .page-section .awards .press-quotes .page-section-text, .page-section .awards .awards .page-section-text {
    margin-bottom: 2rem;
  }
  .page-section .project-stories .tiles-container .tile, .page-section .showrooms .tiles-container .tile {
    height: 40vw;
    min-height: 20rem;
  }
  .page-section .project-stories .tiles-container .tile .tile-title, .page-section .showrooms .tiles-container .tile .tile-title {
    font-size: 8vw;
    letter-spacing: 0.75vw;
    padding: 0 1rem;
  }
  .page-section .project-stories .tiles-container .tile .tile-image-container .tile-image, .page-section .showrooms .tiles-container .tile .tile-image-container .tile-image {
    filter: brightness(60%);
  }
}