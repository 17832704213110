.accordion .accordion-title {
  letter-spacing: 3px;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
.accordion .accordion-description {
  font-size: 1rem;
  margin-bottom: 2rem;
}
.accordion .accordion-description a[href^="mailto:"] {
  cursor: pointer;
}
.accordion .accordion-description a[href^="mailto:"].blue {
  color: rgb(119, 152, 173);
}
.accordion .accordion-description a[href^="mailto:"].blue:hover {
  color: rgb(80, 112, 133);
}
.accordion .accordion-description a[href^="mailto:"]:not(.blue) {
  color: rgb(180, 180, 180);
}
.accordion .accordion-description a[href^="mailto:"]:not(.blue):hover {
  color: rgb(139, 138, 135);
}
.accordion .accordion-section:not(.is-expanded) .accordion-section-content {
  display: none;
  visibility: hidden;
}
.accordion .accordion-section.is-expanded .accordion-section-title {
  color: #7798ad;
}
.accordion .accordion-section .accordion-section-title {
  padding: 0.75rem 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-size: 1rem;
  border-bottom: 1px solid #bbb;
  cursor: pointer;
  user-select: none;
}
.accordion .accordion-section .accordion-section-title:hover {
  opacity: 0.6;
}
.accordion .accordion-section .accordion-section-content {
  margin-bottom: 2rem;
  padding-left: 1rem;
}
.accordion .accordion-section .accordion-section-content .accordion-text-item {
  margin-bottom: 1rem;
}
.accordion .accordion-section .accordion-section-content .accordion-text-item b {
  font-weight: bold;
  opacity: 0.9;
}
.accordion .accordion-text-item-link {
  cursor: pointer;
  color: rgb(180, 180, 180);
}
.accordion .accordion-text-item-link:hover {
  color: rgb(139, 138, 135);
}
.accordion .accordion-text-item-link.next-page {
  margin-top: 3em;
  text-transform: uppercase;
}
.accordion .accordion-text-item-link.blue {
  color: rgb(119, 152, 173);
}
.accordion .accordion-text-item-link.blue:hover {
  color: rgb(80, 112, 133);
}
.accordion .accordion-text-item-link.dark-gray {
  color: rgb(70, 70, 70);
  font-size: 1rem;
}
.accordion .accordion-text-item-link.dark-gray:hover {
  color: rgb(140, 140, 140);
}
.accordion .accordion-text-item-link.next-page {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.accordion .next-page-container {
  margin-top: 5em;
  padding-left: 2rem;
}