@import 'styles/form.scss';
@import 'styles/index.scss';

.inquiry-form {
  height: 100%;
  // -webkit-overflow-scrolling: touch;
  // overflow: auto;

  .radio-input {
    display: block;
  }
  .thank-you-message {
    padding: 2rem;
  }
  .form-and-prompt-wrapper {
    @media #{$desktopDevice} {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .inquiry-form-prompt {
      line-height: 1.4;
      padding: 1rem 2rem;
      border-bottom: $border;

      .prompt-text {
        max-width: 35rem;
        // min-width: 20rem;
      }
    }
    .form-wrapper {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      flex-grow: 1;

      form {
        padding: 2rem;
        padding-top: 1rem;
      }
    }
  }

  .form-input {
    .dropdown {
      .item {
        .text {
          white-space: normal !important;
        }
      }
    }
  }

  //WARNING form is in an iframe, so "width" is the width of the frame
  @media only screen and (min-width: 301px) {
    .form-input {
      &:nth-child(2n + 1) {
        margin-right: 1rem;
      }
    }
  }
  @media only screen and (max-width: 300px) {
    .form-input {
      width: 100%;
    }
  }
}
