@import 'styles/index.scss';

#root {
  &:not(.landing-page) {
    height: 100%;
    min-height: 100%;
  }

  overflow-x: hidden;
}


.app {
  &:not(.landing-page) {
    height: 100%;
    min-height: 100%;
  }

  position: relative;
  left: 0px;

  &, .navigation-tray {
    transition: left 0.4s;
  }

  .app-content {
    height: 100%;
    z-index: 0;
  }
  .app-404 {
    width: 100%;
    height: 100%;

    .text-404 {
      text-align: center;
      position: relative;
      top: calc(50% - 0.7rem);
    }
  }

  @mixin navigation($navigationWidth, $negativeNavigationWidth) {
    &.showing-navigation {
      // left: $navigationWidth;

      .app-navigation {
        width: $navigationWidth;

        .navigation-tray {
          left: 0px;
        }
      }
    }
    &:not(.showing-navigation) {
      .app-navigation {
        @media #{$wideWindow} {
          width: 5rem;
        }
        @media #{$narrowWindow} {
          width: 100%;
          height: 4rem;
        }
        // cursor: pointer;

        .list-item-target {
          .list-item-target-text {
            opacity: 0;
          }
        }

        .navigation-tray {
          left: 0;
        }
      }
    }
  }

  @media #{$wideWindow} {
    @include navigation(50%, -50%);

    .app-content {
      padding-left: 5rem;
    }
    .gdpr-banner {
      right: 0;
      left: auto;
    }
  }
  @media #{$narrowWindow} {
    // @include navigation(calc(100% - 5rem), calc(-1 * (100% - 5rem)));
    @include navigation(calc(100%), calc(-1 * (100%)));

    .app-content {
      padding-top: 4rem;

      // .page-sections {
      //   padding-left: 1rem;
      //   padding-right: 1rem;
      //   padding-top: 1rem;
      // }
    }
  }
  @media #{$mobileDevice} {
    // .app-content {
    //   cursor: pointer;
    //   overflow: auto;
    //   -webkit-overflow-scrolling: touch;
    // }
    //
    // &:not(.showing-navigation) {
    //   .app-navigation {
    //     .app-navigation-list-item, .list-item-target {
    //       pointer-events: none;
    //       // background-color: black;
    //     }
    //   }
    // }
  }


}

@media print {
  @page {
    margin: 0;
    orientation: landscape;
    size: 11in 8.5in;
  }

  html, body, .app-content, .app-page {
    height: auto !important;
    min-height: auto !important;
  }
  .app {
    .app-navigation {
      display: none;
    }
    .app-content {
      padding-left: 0;

      .app-page {
        .page-sections {
          overflow: inherit !important;

          .page-section {
            page-break-after: always;
            border-bottom: none;

            &.layout-text-left-grid-right {
              .grid-cell {
                width: 8rem;
                margin-right: 1rem;
                margin-bottom: 0;
              }
              .cell-group {
                &:not(:first-child) {
                  .grid-cell {
                    margin-top: 3rem;
                  }
                }
              }
            }
            &:not(.layout-text-left-grid-right) {
              height: 8.5in;
            }

            .page-section-logo-text {
              display: none;
            }
          }
        }
      }
    }
  }
}

//popups
.hb-cv-message {
  line-height: 1.4;
  color: #666;

  a {
    color: #333;
    text-decoration: underline;
  }

  @media screen and (min-width: 801px) {
    max-width: 42rem;
    padding: 5rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
  }
}

.hb-cv-message {
  line-height: 1.4;
  color: #666;

  a {
    display: inline-block;
    vertical-align: middle;
  }

  @media screen and (min-width: 801px) {
    max-width: 44rem;
    padding: 5rem 10rem;
    font-size: 2rem;

    span {
      font-size: 1.7rem;
    }
    img {
      width: 2.5rem;
    }
  }
  @media screen and (max-width: 800px) {
    font-size: 1.4rem;
    line-height: 1.5;

    span {
      font-size: 1rem;
    }
    img {
      width: 2.5rem;
    }
  }
}

.popup {
  .popup-window {
    border-radius: 0px;
    // background-color: #eee;
    box-sizing: border-box;
    position: relative;
    height: auto;

    .popup-close-button {
      cursor: pointer;
      width: 2rem !important;
      height: 2rem !important;
      display: block;
      opacity: 0.6;
    }

    @media screen and (min-width: 801px) {
      .close-button {
        position: absolute;
        right: 1rem;
        top: 1rem;
      }
    }
    @media screen and (max-width: 800px) {
      padding: 2rem;
      width: calc(100% - 2rem) !important;

      .close-button {
        margin-left: auto;
        position: relative;
        top: -1rem;
        left: 0.5rem;
      }
    }
  }

  &.type-fitted {
    .popup-window {
      background-color: #eee;

      .popup-content {
        @media screen and (min-width: 801px) {
          padding: 1.5rem 2.5rem;
        }

        .inquiry-popup {
          text-align: center;

          .inquiry-popup-tagline {
            font-size: 1.2em;
            font-weight: bold;
            color: rgb(119, 152, 173);
            margin-bottom: 1rem;
          }
          .inquiry-popup-message {
            margin-bottom: 2rem;
          }
          .inquiry-popup-button {

          }
        }
      }
    }
  }
  &.type-corner {
    .popup-window {
      background-color: #eee;

      @media screen and (min-width: 801px) {
        width: 22rem;

        &.position-left {
          left: 7rem !important;
        }
      }

      .popup-content {
        font-size: 0.8rem;
        line-height: 1.4;

        @media screen and (min-width: 801px) {
          padding: 1.5rem 2.5rem;
        }
        @media screen and (max-width: 800px) {

        }

        .subscribe-popup {
          .subscribe-popup-tagline, .subscribe-popup-message {
            margin-bottom: 1em;
          }

          .subscribe-popup-tagline {
            font-size: 1.2em;
            font-weight: bold;
            color: rgb(119, 152, 173);
          }
          // .subscribe-form-container {
          //   height: 2.8rem;
          //   display: flex;

          //   iframe {
          //     border: none;
          //   }
          // }
        }
      }
    }
  }
}

.subscribe-form-container {
  height: 2.8rem;
  display: flex;

  iframe {
    border: none;
    width: 100%;
  }
}

//WARNING leave this commented out - it causes jittery navigation
// .app-navigation, .gdpr-banner, .app-content, .app {
//   -webkit-transform: translate3d(0,0,0);
// }
