.popup {
  display: flex;
  position: fixed;
  z-index: 54;
  margin: 0;
  padding: 0;
}
.popup.type-pane, .popup.type-fitted {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.popup.type-pane {
  flex-direction: row;
}
.popup.type-pane.position-left {
  justify-content: flex-start;
}
.popup.type-pane.position-right {
  justify-content: flex-end;
}
.popup.type-pane .popup-window {
  max-width: calc(100% - 3rem);
  width: 40rem;
  height: 100%;
}
.popup.type-fitted {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 801px) {
  .popup .popup-close-button {
    position: absolute;
  }
  .popup.type-pane .popup-close-button {
    right: 0.1rem;
  }
  .popup.type-fitted .popup-close-button {
    top: 0.5rem;
    right: 0.5rem;
  }
  .popup.type-corner {
    animation-name: slide-in-from-bottom;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-delay: 2s;
  }
  .popup.type-corner .popup-close-button {
    top: 0.5rem;
    right: 0.5rem;
  }
  .popup.type-corner.position-left {
    left: 2rem;
  }
  .popup.type-corner.position-right {
    right: 2rem;
  }
}
@media screen and (max-width: 800px) {
  .popup.type-pane .popup-close-button, .popup.type-fitted .popup-close-button {
    margin-left: auto;
    position: relative;
    top: -1rem;
    left: 0.5rem;
  }
  .popup.type-corner {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    animation-name: fade-in;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-delay: 2s;
  }
  .popup.type-corner .popup-window {
    width: 80%;
    height: auto;
    margin: auto;
  }
  .popup.type-corner .popup-window .popup-close-button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
}
@keyframes slide-in-from-bottom {
  from {
    bottom: -100%;
  }
  to {
    bottom: 2rem;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.popup .popup-window {
  position: relative;
  background-color: #FFFFFF;
  overflow: auto;
}
.popup .popup-window .popup-close-button {
  height: 3em;
  width: 3em;
  cursor: pointer;
  z-index: 1;
}