@import 'lib/styles/import.scss';

.dropdown-input {
  width: $inputWidth;
}
.dropdown.selection {
  position: relative;
  width: 100%;

  &.active {
    > .text {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    > .icon {
      top: 0.6rem;

      &:before {
        content: "▲";
      }
    }

    .menu {
      z-index: 1;
    }
  }
  &:not(.active) {
    &:hover {
      opacity: $hoverOpacity;
    }

    .menu {
      display: none;
    }
    > .icon {
      top: 0.8rem;

      &:before {
        content: "▼";
      }
    }
  }

  > .text, > .icon {
    cursor: pointer;
  }
  > .text {
    padding: $textBoxPadding;
    padding-right: 2rem;
    border-radius: $borderRadius;
  }

  .text {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  > .icon {
    font-style: normal;
    position: absolute;
    right: 0.7rem;
    font-size: 0.7em;
    opacity: 0.6;
  }
  .menu {
    position: absolute;
    top: 100%;
    width: 100%;
    border-radius: $borderRadius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .item {
      &:not(.active) {
        cursor: pointer;
      }

      .text {
        padding: $textBoxPadding;
      }
    }
  }
}

.theme-light {
  .dropdown.selection {
    &.active {
      > .text, .menu {
        box-shadow: $lightBoxShadow;
      }
    }
    > .text, .menu {
      background-color: $lightInputBackgroundColor;
    }

    > .text {
      border: $lightBorder;
    }

    .menu {
      border: $lightBorder;
      border-top: none;

      .item {
        &.active {
          background-color: rgba(0, 0, 0, 0.05);
        }
        &:not(.active):hover {
          background-color: rgba(0, 0, 0, 0.025);
        }
      }
    }
  }
}
