.app-navigation {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  height: 100%;
  transition: width 0.4s opacity 0.5s;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.app-navigation a {
  text-decoration: underline dotted rgba(0, 0, 0, 0.4);
}
.app-navigation.inquiry-form-is-visible .navigation-list {
  display: none;
  opacity: 0;
}
.app-navigation:not(.inquiry-form-is-visible) .inquiry-form-container {
  opacity: 0;
  display: none;
}
.app-navigation:not(.is-visible) .navigation-list {
  display: none;
  opacity: 0;
}
.app-navigation:not(.is-visible) .instagram-link {
  opacity: 0;
}
.app-navigation .app-navigation-icons-container {
  position: absolute;
  top: 0;
  background-color: #FFF;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container {
    height: 100%;
    width: 100%;
    border-right: 1px solid #e5e5e5;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-icons-container {
    display: flex;
    flex: 1;
    width: 100%;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    margin: auto;
    border-bottom: 1px solid #e5e5e5;
    z-index: 5;
  }
}
.app-navigation .app-navigation-icons-container .home-icon-container, .app-navigation .app-navigation-icons-container .navigation-icon-container, .app-navigation .app-navigation-icons-container .discuss-a-project-container {
  z-index: 2;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .home-icon-container, .app-navigation .app-navigation-icons-container .navigation-icon-container, .app-navigation .app-navigation-icons-container .discuss-a-project-container {
    position: absolute;
  }
}
.app-navigation .app-navigation-icons-container .home-icon-container img, .app-navigation .app-navigation-icons-container .navigation-icon-container img, .app-navigation .app-navigation-icons-container .discuss-a-project-container img {
  height: 100%;
  cursor: pointer;
}
.app-navigation .app-navigation-icons-container .home-icon-container {
  height: 1.2rem;
  width: 1.2rem;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .home-icon-container {
    top: 2rem;
    left: 1.9rem;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-icons-container .home-icon-container {
    display: none;
  }
}
.app-navigation .app-navigation-icons-container .home-icon-container:hover {
  opacity: 0.5;
}
.app-navigation .app-navigation-icons-container .navigation-icon-container {
  height: 2rem;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .navigation-icon-container {
    top: 50%;
    left: 1rem;
  }
}
.app-navigation .app-navigation-icons-container .navigation-icon-container img {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}
.app-navigation .app-navigation-icons-container .navigation-icon-container img:hover {
  opacity: 0.2;
}
.app-navigation .app-navigation-icons-container .navigation-icon-container.hamburger {
  width: 3rem;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .navigation-icon-container.hamburger {
    left: 1rem;
  }
}
.app-navigation .app-navigation-icons-container .navigation-icon-container.close {
  width: 2rem;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .navigation-icon-container.close {
    left: 1.5rem;
  }
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .discuss-a-project-container {
    bottom: 0;
    padding: 1em 0;
    width: 5rem;
    border-top: 1px solid #e5e5e5;
    background-color: rgba(0, 0, 0, 0.9);
    color: #FFF;
  }
  .app-navigation .app-navigation-icons-container .discuss-a-project-container:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-icons-container .discuss-a-project-container {
    right: 0;
    padding: 2vw;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
}
.app-navigation .app-navigation-icons-container .discuss-a-project-container .discuss-a-project-text {
  text-align: center;
  text-transform: uppercase;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-icons-container .discuss-a-project-container .discuss-a-project-text {
    font-size: 0.5em;
    letter-spacing: 0.2em;
    line-height: 2em;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-icons-container .discuss-a-project-container .discuss-a-project-text {
    font-size: 2.5vw;
    letter-spacing: 0.7vw;
    color: rgba(0, 0, 0, 0.75);
  }
}
.app-navigation .app-navigation-content {
  height: 100%;
  width: 100%;
  background-color: #FFF;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-content {
    padding-left: 5rem;
  }
}
.app-navigation .app-navigation-content .navigation-tray {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  z-index: 1;
}
.app-navigation .app-navigation-content .navigation-list {
  font-size: 1.2rem;
  position: absolute;
  z-index: 2;
  overflow: auto;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-content .navigation-list {
    width: calc(100% - 5rem);
    height: 100%;
    padding-top: calc(2rem - 6px);
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-content .navigation-list {
    top: 4rem;
    height: calc(100% - 4rem);
    width: 100%;
    padding-top: 1rem;
  }
}
.app-navigation .app-navigation-content .navigation-list .instagram-link {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  transition: opacity 0.5s;
  padding: 2px 2rem;
}
@media only screen and (max-device-width: 1024px) {
  .app-navigation .app-navigation-content .navigation-list .instagram-link {
    padding: 2px 1.5rem;
  }
}
.app-navigation .app-navigation-content .navigation-list .instagram-link a img {
  width: 18px;
  opacity: 0.7;
}
.app-navigation .app-navigation-content .navigation-list .instagram-link a img:hover {
  opacity: 0.4;
}
.app-navigation .app-navigation-content .inquiry-form-container {
  z-index: 3;
  width: 100%;
  height: 100%;
  position: relative;
  border-left: 1px solid #e5e5e5;
  overflow: auto;
  animation: showHideForm 0.5s;
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-content .inquiry-form-container {
    padding-top: 4rem;
  }
}
@keyframes showHideForm {
  0% {
    opacity: 0;
    display: none;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
.app-navigation .app-navigation-content .inquiry-form-container .hide-inquiry-form {
  cursor: pointer;
  padding: 1rem;
  user-select: none;
  opacity: 0.8;
  color: rgb(70, 70, 70);
  letter-spacing: 1px;
}
@media print, (min-width: 801px) {
  .app-navigation .app-navigation-content .inquiry-form-container .hide-inquiry-form {
    margin-left: 0rem;
    padding-left: 2rem;
  }
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-content .inquiry-form-container .hide-inquiry-form {
    padding-left: 3rem;
  }
}
.app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-section-container {
  border-top: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  padding: 1rem;
}
@media print, (min-device-width: 1025px) {
  .app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-section-container {
    padding: 1rem 2rem;
  }
}
@media only screen and (max-device-width: 1024px) {
  .app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-section-container {
    padding: 1rem 3rem;
  }
}
.app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-section-container .inquiry-form-section-content-container .inquiry-form-section-text, .app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-section-container .inquiry-form-section-content-container .inquiry-form-section-title {
  line-height: 1.25;
}
.app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-section-container .inquiry-form-section-content-container .inquiry-form-section-title {
  font-size: 1.2rem;
  margin-bottom: 6px;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input {
  display: flex;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input .radio {
  flex-grow: 1;
  margin-bottom: 0;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input .radio input {
  display: none;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input .radio label {
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  text-align: center;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input .radio:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}
.app-navigation .app-navigation-content .inquiry-form-container .contact-type-input-container .inline-radio-input .radio.checked label {
  color: rgb(119, 152, 173);
}
.app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-message {
  border-top: 1px solid #e5e5e5;
  padding: 1rem;
  text-align: center;
  color: crimson;
}
@media only screen and (max-width: 800px) {
  .app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-message {
    font-size: 75%;
  }
}
.app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-iframe-container {
  width: 100%;
  height: calc(100% - 3.5rem);
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  border-top: 1px solid #e5e5e5;
}
.app-navigation .app-navigation-content .inquiry-form-container .inquiry-form-iframe-container .inquiry-form-iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
}
.app-navigation .app-navigation-content .inquiry-form-container .showrooms-info-container {
  border-top: 1px solid #e5e5e5;
}
.app-navigation .app-navigation-content .inquiry-form-container .showrooms-info-container .info-block {
  line-height: 1.4;
  padding: 1rem 2rem;
  max-width: 20rem;
}
.app-navigation .app-navigation-content .inquiry-form-container .showrooms-info-container .info-block a {
  text-decoration: underline;
}