.button {
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.theme-light {
  .button, .text-input input {
    font-size: 0.9rem;
  }
  .button, .text-input input, .dropdown.selection > .text, .dropdown.selection .menu {
    border: 1px solid #ddd;
  }
  .text-input {
    input {
      width: 100%;
    }
  }
  label, .label {
    padding: 0.4rem 1rem;
    padding-top: 0.6rem;
  }
}
