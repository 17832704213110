@import 'styles/index.scss';

// @FB
// constraints style file
// Related: constraints

.constraints {
  display: block;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  padding: 1rem 2rem;

  .constraint-title, .constraint-value, .constraint-values, .step-line {
    display: inline-block;
    vertical-align: middle;
  }

  .constraint {
    padding: 0.2rem 0;

    .constraint-title {
      // font-weight: bold;
      text-transform: lowercase;
      width: 1.5rem;
      opacity: 0.5;
      font-size: 1.3rem;
      margin-right: 0.5rem;
    }

    .constraint-values {
      white-space: normal;
      max-width: calc(100% - 2rem);

      .constraint-value {
        font-size: 1rem;
        white-space: nowrap;
        line-height: 1.5;

        &.constraint-value-min, &.constraint-value-max, &.constraint-value-fixed {
          padding: 0.1rem 0.6rem;
          border: 1px solid black;

          &::after {
            content: "\0022";
          }
        }
        &.constraint-value-step {
          // width: 1.2rem;
          padding-left: 0.5rem;
          text-align: center;
        }
        &.constraint-value-fixed {
          margin-left: -1px;
          margin-top: -1px;
          min-width: 2.6rem;
        }
        &.constraint-value-line {
          height: 0;
          text-align: center;
          width: 0.4rem;
          border-bottom: 1px solid black;
        }
        &.constraint-value-antecedent {
          padding: 0rem 0.3rem;
          text-align: center;

          &.fixed {
            &::after {
              content: "";
            }
            &:nth-last-child(3)::after {
              content: "or";
              padding: 0rem 0.5rem;
            }
            &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
}
