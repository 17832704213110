.button {
  cursor: pointer;
}
.button:hover {
  opacity: 0.7;
}

.theme-light .button, .theme-light .text-input input {
  font-size: 0.9rem;
}
.theme-light .button, .theme-light .text-input input, .theme-light .dropdown.selection > .text, .theme-light .dropdown.selection .menu {
  border: 1px solid #ddd;
}
.theme-light .text-input input {
  width: 100%;
}
.theme-light label, .theme-light .label {
  padding: 0.4rem 1rem;
  padding-top: 0.6rem;
}

.inquiry-form {
  height: 100%;
}
.inquiry-form .radio-input {
  display: block;
}
.inquiry-form .thank-you-message {
  padding: 2rem;
}
@media print, (min-device-width: 1025px) {
  .inquiry-form .form-and-prompt-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.inquiry-form .form-and-prompt-wrapper .inquiry-form-prompt {
  line-height: 1.4;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e5e5e5;
}
.inquiry-form .form-and-prompt-wrapper .inquiry-form-prompt .prompt-text {
  max-width: 35rem;
}
.inquiry-form .form-and-prompt-wrapper .form-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.inquiry-form .form-and-prompt-wrapper .form-wrapper form {
  padding: 2rem;
  padding-top: 1rem;
}
.inquiry-form .form-input .dropdown .item .text {
  white-space: normal !important;
}
@media only screen and (min-width: 301px) {
  .inquiry-form .form-input:nth-child(2n+1) {
    margin-right: 1rem;
  }
}
@media only screen and (max-width: 300px) {
  .inquiry-form .form-input {
    width: 100%;
  }
}