@import 'styles/index.scss';

.app-page {
  height: 100%;
  min-height: 100%;

  .page-sections {
    height: 100%;
    min-height: 100%;
    overflow: auto;
    // scroll-snap-type: y proximity;

    @media #{$mobileDevice} {
      cursor: pointer;
      -webkit-overflow-scrolling: touch;
    }
  }
}
