@import './import.scss';

* {
  box-sizing: border-box;
  // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.button, input, .form-input, .dropdown.selection {
  outline: none;
  -webkit-appearance: none;
}
.button, .text-input input, .text-input textarea, textarea, .date-input input {
  border-radius: $borderRadius;
  padding: $textBoxPadding;
  border: none;
  font-size: 1rem;
}
.label, label {
  padding: 0.5rem 1rem;
  display: block;
}
.form-input {
  display: inline-block;
  vertical-align: bottom;
}

.text-input input, .button {
  border: $lightBorder;
}
.date-input, .text-input {
  input:focus {
    border: $lightFocusedBorder;
  }
  // box-shadow: $lightBoxShadow;
}
.text-input, .date-input {
  input, textarea {
    background-color: $lightInputBackgroundColor;
  }
}
.button {
  background-color: black;
  color: white;
}
.radio-input input {
  background-color: #eeeeee;

  &:checked {
    background-color: #999;
    border-color: #999;
  }
  &:not(:checked):hover {
    background-color: #e6e6e6;
  }
}
