@import 'styles/index.scss';

.page-section-text {
  font-size: 1rem;

  .text-container {
    width: 100%;
    height: 100%;
    display: flex;
    line-height: 1.4;

    .text-content {
      white-space: pre-line;

      .page-section-text-item {
        transition: opacity 1s;

        &:not(.is-visible) {
          opacity: 0;
        }
        &.serif {
          // font-family: 'Frank Ruhl Libre';
        }
        &.sans-serif {
          // font-family: 'Roboto', sans-serif;
        }
      }
      .text-item-plain {
        &:not(:last-child) {
          margin-bottom: 1em;
        }
      }
      .text-item-heading {
        font-size: 1.2em;
        margin-bottom: 1em;
        font-weight: bold;
        //font-size: 2em;
        color: rgb(119, 152, 173);
        // margin-bottom: 1.5em;
      }
      .text-item-sub-heading {
        // font-weight: bold;
        color: rgb(119, 152, 173);
        margin-top: 1em;
        margin-bottom: 1em;
      }
      .text-item-larger-text {
        font-size: 110%;
      }
      .text-item-larger-text-space-after {
        font-size: 110%;
        margin-bottom: 1em;

        &.blue {
          color: rgb(119, 152, 173);
        }
      }
      .text-item-smaller-text {
        font-size: 75%;

        &.blue {
          color: rgb(119, 152, 173);
        }
      }
      .text-item-smaller-text-space-after{
        margin-bottom: 1em;
        font-size: 75%;

        &.blue {
          color: rgb(119, 152, 173);
        }
      }
      .text-item-link {
        display: block;
        text-transform: uppercase;
        line-height: 1.6;

        &.blue {
          color: rgb(119, 152, 173);

          &:hover {
            color: rgb(80, 112, 133);
          }
        }
        &:not(.blue) {
          color: rgb(180, 180, 180);

          &:hover {
            color: rgb(139, 138, 135);
          }
        }

        &.next-page {
          margin-top: 3em;
        }

        &.larger-text {
          font-size: 80%;
        }
      }
      a:not(.text-item-link), .pseudo-link {
        cursor: pointer;
        text-decoration: underline;
      }
      a.no-underline {
        text-decoration: none;

        &.blue {
          color: rgb(119, 152, 173);

          &:hover {
            color: rgb(80, 112, 133);
          }
        }
        &:not(.blue) {
          color: rgb(180, 180, 180);

          &:hover {
            color: rgb(139, 138, 135);
          }
        }
      }
      a.phone {
        text-decoration: none;
      }

      @media #{$narrowWindow} {
        padding: $padding;
      }
      @media #{$wideWindow} {
        padding: $padding;
      }
    }
    .quote-content, .award-content {
      width: 100%;

      a {
        &.blue {
          color: rgb(119, 152, 173);

          &:hover {
            color: rgb(80, 112, 133);
          }
        }
      }
    }
    .quote-content {
      .quote-author {
        margin-top: 0.75rem;
        text-align: right;
        color: rgb(119, 152, 173);

        &:hover {
          color: rgb(80, 112, 133);
        }
      }
    }
    .award-content  {
      .award-media {
        height: 5rem;
        margin-bottom: 1rem;

        img {
          height: 100%;
        }
      }
      .award-title-container {
        margin-bottom: 1rem;

        .award-title{
          margin-top: 0.75rem;
          color: rgb(119, 152, 173);

          &:hover {
            color: rgb(80, 112, 133);
          }
        }
      }
    }
  }

  @media #{$wideWindow} {
    .text-container {
      &.align-y-top {
        align-items: flex-start;
      }
      &.align-y-center {
        align-items: center;
      }
      &.align-y-bottom {
        align-items: flex-end;
      }
      &.align-x-left {
        justify-content: flex-start;
      }
      &.align-x-center {
        justify-content: center;
        position: relative;
      }
      &.align-x-right {
        justify-content: flex-end;
      }
    }
  }
}
