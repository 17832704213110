.app-page {
  height: 100%;
  min-height: 100%;
}
.app-page .page-sections {
  height: 100%;
  min-height: 100%;
  overflow: auto;
}
@media only screen and (max-device-width: 1024px) {
  .app-page .page-sections {
    cursor: pointer;
    -webkit-overflow-scrolling: touch;
  }
}