@import 'styles/index.scss';

.app-navigation-list-item {
  user-select: none;
  transition: opacity 0.9s, left 0.4s;
  position: relative;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.is-active > .list-item-target {
    .list-item-target-text {
      color: rgb(119, 152, 173);
    }
  }
  &:not(.is-expanded) {
    .list-item-children{
      display: none;
    }
  }
  &.space-before {
    margin-top: 1.5rem;
  }
  &.list-item-level-0 {
    .list-item-children {
      border-left: 1px solid rgba(70, 70, 70, 0.4);

      .list-item-target-text {
        // padding-left: 2rem;
      }
    }
  }
  &:not(.is-visible) {
    opacity: 0;
    left: -1rem;
  }

  .list-item-target {
    display: flex;
    cursor: pointer;
    // white-space: nowrap;

    .list-item-target-content {
      cursor: pointer;
      // white-space: nowrap;
      // display: flex;

      .list-item-target-text {
        transition: opacity 0.4s;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  @media #{$desktopDevice} {
    .list-item-target {
      padding: 3px $padding;

      &:hover {
        opacity: 0.6;
      }
    }
    .list-item-children.has-children {
      margin: 0.5rem 0rem;
      margin-left: 3rem;
    }
  }
  @media #{$mobileDevice} {
    .list-item-target {
      padding: 0.3rem 1.5rem;
      font-size: 1.25rem;
    }
    .list-item-children.has-children {
      margin: 0.5rem 0rem;
      margin-left: 2rem;
    }
  }
}
