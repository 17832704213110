@import 'styles/index.scss';

.page-section-medium {
  overflow: hidden;
  position: relative;
  transition: opacity 0.8s;

  .medium-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);

    .project-story-title-container {
      margin: auto;
      color: #FFF;
      text-align: center;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

      .project-story-title {

      }
      .project-story-location {

      }
    }
  }

  .medium-image {
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
    }
  }

  @media #{$narrowWindow} {
    height: auto;

    // &.orientation-square {
    //   .medium-image {
    //     width: 80%;
    //     margin-left: 20%;
    //   }
    // }

    .medium-overlay {
      .project-story-title-container {
        padding: 0 $padding / 2;

        .project-story-title {
          font-size: 7vw;
          letter-spacing: 0.7vw;
          margin-bottom: 1vw;
        }
        .project-story-location {
          font-size: 4vw;
          letter-spacing: 0.3vw;
        }
      }
    }

    .medium-image {
      background: none !important;
    }
    video {
      width: 100%;
    }
  }

  @media #{$wideWindow} {
    height: 100%;

    .medium-overlay {
      .project-story-title-container {
        padding: 0 $padding * 2;

        .project-story-title {
          font-size: 5.5vw;
          letter-spacing: 0.4vw;
          margin-bottom: 2rem;
        }
        .project-story-location {
          font-size: 3vw;
          letter-spacing: 0.2vw;
        }
      }
    }

    .medium-image {
      background-size: cover;
      background-position: center;

      img {
        height: 100%;
        opacity: 0;
        position: absolute;
      }
    }

    &.position-top {
      .medium-image {
        background-position: top;
      }
    }
    &.position-bottom {
      .medium-image {
        background-position: bottom;
      }
    }

    .medium-video {
      width: 100%;
      height: 100%;

      video{
        width: 100%;
        height: 100%;
      }
    }
  }
}
