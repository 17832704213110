.popup {
  display: flex;
  position: fixed;
  z-index: 54;
  margin: 0;
  padding: 0;

  &.type-pane, &.type-fitted {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  &.type-pane {
    flex-direction: row;

    &.position-left {
      justify-content: flex-start;
    }
    &.position-right {
      justify-content: flex-end;
    }
    .popup-window {
      max-width: calc(100% - 3rem);
      width: 40rem;
      height: 100%;
    }
  }
  &.type-fitted {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 801px) {
    .popup-close-button {
      position: absolute;

    }

    &.type-pane {
      .popup-close-button {
        right: 0.1rem;
      }
    }
    &.type-fitted {
      .popup-close-button {
        top: 0.5rem;
        right: 0.5rem;
      }
    }
    &.type-corner {
      animation-name: slide-in-from-bottom;
      animation-fill-mode: forwards;
      animation-duration: 1s;
      animation-delay: 2s;

      .popup-close-button {
        top: 0.5rem;
        right: 0.5rem;
      }

      &.position-left {
        left: 2rem;
      }
      &.position-right {
        right: 2rem;
      }
    }
  }
  @media screen and (max-width: 800px) {
    &.type-pane, &.type-fitted {
      .popup-close-button {
        margin-left: auto;
        position: relative;
        top: -1rem;
        left: 0.5rem;
      }
    }
    &.type-corner {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      animation-name: fade-in;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
      animation-delay: 2s;

      .popup-window {
        width: 80%;
        height: auto;
        margin: auto;

        .popup-close-button {
          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
        }
      }
    }
  }

  @keyframes slide-in-from-bottom {
    from {
      bottom: -100%;
    }
    to {
      bottom: 2rem;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .popup-window {
    position: relative;
    background-color: #FFFFFF;
    overflow: auto;

    .popup-close-button {
      height: 3em;
      width: 3em;
      cursor: pointer;
      z-index: 1;
    }
  }
}
