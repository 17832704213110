@import 'styles/index.scss';

// @FB
// slideshow style file
// Related: slideshow

.slideshow {
  width: 100%;
  height: 100%;

  &.is-fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0px;
    right: 0px;
    z-index: $zSlideshow;
    //....
  }

  .slideshow-content {
    position: relative;
    width: 100%;
    height: 100%;

    .slideshow-media, .slideshow-slides {
      height: 100%;
      width: 100%;

      .slideshow-medium, .slideshow-slide {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        // z-index: 1;
        transition: opacity 0.5s; //, display 0.5s 0s;

        &:not(.active) {
          opacity: 0;
          z-index: 0; //WARNING z-index here keeps the active medium in front, which allows you to right click to view image source
          pointer-events: none;
        }
      }
    }

    .slideshow-media {
      .slideshow-medium {
        background-position: center;
        background-color: black;
        background-size: cover;
        background-repeat: no-repeat;

        img {
          height: 100%;
          width: 100%;
        }

        &.size-mode-contain {
          img {
            object-fit: contain;
          }
        }
        &.size-mode-cover {
          img {
            object-fit: cover;
          }
        }
      }
    }
    .slideshow-controls {
      position: absolute;
      display: flex;
      // z-index: 1;

      &.show-background {
        background-color: rgba(0, 0, 0, 0.3);
      }
      &.position-right, &.position-left {
        top: 0px;
        width: 3rem;
        height: 100%;
        flex-direction: column;
        justify-content: flex-end;

        .slideshow-dots {
          flex-direction: column;
          padding: 0.5rem 0;
        }
        .slideshow-prev {
          padding-top: 0.7rem;
          padding-bottom: 0.4rem;

          .arrow {
            transform: rotate(-135deg);
          }
        }
        .slideshow-next {
          padding-top: 0.4rem;
          padding-bottom: 0.7rem;

          .arrow {
            transform: rotate(45deg);
          }
        }
      }
      &.position-right {
        right: 0px;
      }
      &.position-left {
        left: 0px;
      }
      &.position-top, &.position-bottom {
        left: 0px;
        height: 3rem;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .slideshow-dots {
          padding: 0 0.5rem;
        }
        .slideshow-prev {
          padding-left: 0.7rem;
          padding-right: 0.4rem;

          .arrow {
            transform: rotate(135deg);
          }
        }
        .slideshow-next {
          padding-left: 0.4rem;
          padding-right: 0.7rem;

          .arrow {
            transform: rotate(-45deg);
          }
        }
      }
      &.position-top {
        top: 15px;
      }
      &.position-bottom {
        bottom: 15px;
      }

      .slideshow-dots {
        position: relative;
        display: flex;

        .slideshow-dot-container {
          padding: 0.4rem;
          cursor: pointer;

          &:hover .slideshow-dot {
            background-color: rgba(255, 255, 255, 0.3);
          }
          &.active .slideshow-dot {
            background-color: white;
          }

          .slideshow-dot {
            $diameter: 10px;

            width: $diameter;
            height: $diameter;
            border-radius: $diameter / 2;
            border: 2px solid white;
            margin: auto;
          }
        }
      }
      .slideshow-control-button {
        cursor: pointer;

        &:hover{
          background-color: rgba(0, 0, 0, 0.1);
        }

        .arrow {
          border: solid white;
          border-width: 0 2px 2px 0;
          width: 0;
          height: 0;
          padding: 5px;
          margin: auto;
        }
      }
      .slideshow-prev, .slideshow-next {
        height: 2rem;
        display: flex;
      }
      .slideshow-toggle-fullscreen {
        margin-top: 1.5rem;
        padding: 0.9rem;
        width: 3rem;
        height: 3rem;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  @media #{$mobileDevice} {
    &.is-fullscreen {
      width: calc(100% - 5rem);
    }
  }
}
