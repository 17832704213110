@import 'styles/index.scss';

.app-navigation {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: $zNavigation;
  height: 100%;
  transition: width 0.4s opacity 0.5s;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;

  a {
    text-decoration: underline dotted rgba(0,0,0,0.4);
  }

  &.inquiry-form-is-visible {
    .navigation-list {
      display: none;
      opacity: 0;

      // @keyframes showHideList {
      //   0% {
      //     opacity: 1;
      //     display: block;
      //   }
      //   50% {
      //     opacity: 0;
      //     display: none;
      //   }
      //   100% {
      //     opacity: 0;
      //     display: none;
      //   }
      // }
      //
      // animation: showHideList 0.5s;
    }
  }
  &:not(.inquiry-form-is-visible) {
    .inquiry-form-container {
      opacity: 0;
      display: none;
    }
  }

  &:not(.is-visible) {
    .navigation-list {
      display: none;
      opacity: 0;
    }

    .instagram-link {
      opacity: 0;
    }
  }

  .app-navigation-icons-container {
    position: absolute;
    top: 0;
    background-color: #FFF;

    @media #{$wideWindow} {
      height: 100%;
      width: 100%;
      border-right: $border;
    }
    @media #{$narrowWindow} {
      display: flex;
      flex: 1;
      width: 100%;
      height: 4rem;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      margin: auto;
      border-bottom: $border;
      z-index: 5;
    }

    .home-icon-container, .navigation-icon-container, .discuss-a-project-container {
      @media #{$wideWindow} {
        position: absolute;
      }
      @media #{$narrowWindow} {

      }

      z-index: 2;

      img {
        height: 100%;
        cursor: pointer;
      }
    }

    .home-icon-container {
      @media #{$wideWindow} {
        top: 2rem;
        left: 1.9rem;
      }
      @media #{$narrowWindow} {
        display: none;
      }

      height: 1.2rem;
      width: 1.2rem;

      &:hover {
        opacity: 0.5;
      }
    }

    .navigation-icon-container {
      height: 2rem;

      @media #{$wideWindow} {
        top: 50%;
        left: 1rem;
      }

      img {
        height: 100%;
        width: 100%;
        opacity: 0.5;

        &:hover {
          opacity: 0.2;
        }
      }
      &.hamburger {
        width: 3rem;

        @media #{$wideWindow} {
          left: 1rem;
        }
      }
      &.close {
        width: 2rem;

        @media #{$wideWindow} {
          left: 1.5rem;
        }
      }
    }

    .discuss-a-project-container {
      @media #{$wideWindow} {
        bottom: 0;
        padding: 1em 0;
        width: 5rem;
        border-top: $border;
        background-color: rgba(0, 0, 0, 0.9);
        color: #FFF;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
      @media #{$narrowWindow} {
        right: 0;
        padding: 2vw;
        border: 1px solid rgba(0, 0, 0, 0.25);
      }


      .discuss-a-project-text {
        @media #{$wideWindow} {
          font-size: 0.5em;
          letter-spacing: 0.2em;
          line-height: 2em;
        }
        @media #{$narrowWindow} {
          font-size: 2.5vw;
          letter-spacing: 0.7vw;
          color: rgba(0, 0, 0, 0.75);
        }

        text-align: center;
        text-transform: uppercase;
      }
    }
  }

  .app-navigation-content {
    height: 100%;
    width: 100%;
    background-color: #FFF;

    @media #{$wideWindow} {
      padding-left: 5rem;
    }
    @media #{$narrowWindow} {
      // margin-top: 5rem;
    }

    .navigation-tray {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0px;
      z-index: 1;
    }
    .navigation-list {
      @media #{$wideWindow} {
        width: calc(100% - 5rem);
        height: 100%;
        padding-top: calc(2rem - 6px);
        border-right: $border;
        border-left: $border;
      }
      @media #{$narrowWindow} {
        top: 4rem;
        height: calc(100% - 4rem);
        width: 100%;
        padding-top: 1rem;
      }

      font-size: 1.2rem;
      position: absolute;
      z-index: 2;
      overflow: auto;

      .instagram-link {
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;
        transition: opacity 0.5s;
        padding: 2px 2rem;

        @media #{$mobileDevice} {
          padding: 2px 1.5rem;
        }

        a {
          img {
            width: 18px;
            opacity: 0.7;

            &:hover {
              opacity: 0.4;
            }
          }
        }
      }
    }
    .inquiry-form-container {
      z-index: 3;
      width: 100%;
      height: 100%;
      position: relative;
      border-left: $border;
      // top: -2rem;
      overflow: auto;

      @media #{$narrowWindow} {
        padding-top: 4rem;
      }

      @keyframes showHideForm {
        0% {
          opacity: 0;
          display: none;
        }
        50% {
          opacity: 0;
          display: none;
        }
        100% {
          opacity: 1;
          display: block;
        }
      }

      animation: showHideForm 0.5s;

      .hide-inquiry-form {
        // font-size: 1.2rem;
        // width: 4rem;
        cursor: pointer;
        padding: 1rem;
        user-select: none;
        opacity: 0.8;
        color: rgb(70, 70, 70);
        letter-spacing: 1px;

        @media #{$wideWindow} {
          margin-left: 0rem;
          padding-left: 2rem;
        }
        @media #{$narrowWindow} {
          padding-left: 3rem;
        }
      }

      .inquiry-form-section-container {
        border-top: $border;
        display: flex;
        flex-direction: column;
        letter-spacing: 1px;
        padding: 1rem;

        @media #{$desktopDevice} {
          padding: 1rem 2rem;
        }
        @media #{$mobileDevice} {
          padding: 1rem 3rem;
        }

        .inquiry-form-section-content-container {
          .inquiry-form-section-text, .inquiry-form-section-title {
            line-height: 1.25;
          }
          .inquiry-form-section-title {
            font-size: 1.2rem;
            margin-bottom: 6px;
          }
        }
      }

      .contact-type-input-container {
        // border-top: $border;

        .inline-radio-input {
          display: flex;

          .radio {
            flex-grow: 1;
            margin-bottom: 0;

            input {
              display: none;
            }
            label {
              width: 100%;
              padding: 1rem;
              cursor: pointer;
              text-align: center;
            }

            &:not(:last-child) {
              border-right: $border;
            }

            &.checked {
              label {
                color: rgb(119, 152, 173);
              }
            }
          }
        }
      }

      .inquiry-form-message {
        border-top: $border;
        padding: 1rem;
        text-align: center;
        color: crimson;

        @media #{$narrowWindow} {
          font-size: 75%;
        }
      }

      .inquiry-form-iframe-container {
        width: 100%;
        height: calc(100% - 3.5rem);
        -webkit-overflow-scrolling: touch;
        overflow: auto;
        border-top: $border;

        .inquiry-form-iframe {
          width: 100%;
          height: 100%;
          display: block;
          border: none;
        }
      }

      .showrooms-info-container {
        border-top: $border;

        .info-block {
          line-height: 1.4;
          padding: 1rem 2rem;
          max-width: 20rem;

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
