@import 'styles/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

html, body {
  height: 100%;
  min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, a, .button, .text-input input,
.text-item-link {
  color: rgb(70, 70, 70);
  font-family: 'Roboto', sans-serif;
}
a {
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

@media #{$mobileDevice} {
  body {
    &.no-scroll-mobile {
      // position: fixed;
      // overflow: hidden;
    }
  }
}
@media #{$desktopDevice} {
  body {
    scroll-snap-type: y;
  }
}

//device fixes
html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}
