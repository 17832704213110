@import 'styles/index.scss';

.page-section.layout-text-left-grid-right:first-child {
  .page-section-grid {
    margin-bottom: 2rem;
  }
}

.page-section-grid {
  width: 100%;
  overflow: hidden;
  position: relative;

  &:not(.cell-size-mode-auto) {
    .cell-thumbnail {
      img {
        width: 100%;
      }
    }
  }
  &.cell-size-mode-auto {
    .grid-cell {
      .cell-thumbnail img {
        display: block;
      }
    }
  }
  &:not(.all-cells-are-loaded) {
    .grid-cell, .cell-group-title {
      opacity: 0;
    }
  }

  .grid-cell-details {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: $zPopup;

    @keyframes show {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
    @keyframes hide {
      0% { opacity: 1;  }
      100% { opacity: 0; }
    }

    &.is-visible {
      animation: show 0.5s;
    }
    &:not(.is-visible) {
      opacity: 0;
      animation: hide 0.5s;
    }

    .details-background {
      overflow: auto;
      background-color: rgba(0, 0, 0, 0.25);
      height: 100%;
      width: 100%;
      position: relative;

      .details-pane {
        height: 100%;
        background-color: white;
        color: #000;
        -webkit-overflow-scrolling: touch;
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0px;
        top: 0px;

        .details-header {
          display: flex;
          border-bottom: 1px solid #ddd;
          min-height: 4rem;

          .cell-title {
            font-size: 1.5rem;
            text-transform: lowercase;
            padding: 1rem 2rem;
            width: 100%;
          }
          .cell-close-details {

          }
        }

        .details-content {
          overflow: auto;
          flex-grow: 1;

          .constraints-container {
            max-height: 9rem;
          }

          .details-notes {
            display: inline-block;
            margin: 1rem 0rem;
            padding-left: 3rem;
            overflow-wrap: break-word;

            .details-note {
              text-transform: lowercase;
              padding: 0.2rem 0rem;
            }
          }
        }
      }
    }
  }
  .cell-groups {
    .cell-group {
      .cell-group-title {
        padding: 0.5rem 0;
        font-weight: bold;
        font-size: 0.9rem;
      }
      .cell-group-cells {
        .grid-cell {
          display: inline-block;
          vertical-align: bottom;
          text-align: left;
          position: relative;
          margin-bottom: 2rem;

          &.has-details {
            cursor: pointer;

            &:hover {
              .cell-title {
                text-decoration: underline;
              }
            }
          }

          .cell-title {
            font-size: 0.8rem;
            margin-top: 0.5rem;
            height: 2rem;
            text-transform: lowercase;
          }
          .cell-thumbnail {
            &.is-loaded img {
              max-width: 100%;
            }
            &:not(.is-loaded) img {
              position: absolute;
            }
            &.has-error img {
              display: none;
            }
          }

          .cell-thumbnail {
            &.has-border {
              img {
                border: 1px solid rgba(0, 0, 0, 0.1);
              }
            }
          }
        }
      }
    }
  }

  @mixin gridCell($count) {
    $size: calc(100% / #{$count} - #{$count - 1} * 2rem / #{$count});

    width: $size;

    &:not(:nth-child(#{$count}n)) {
      margin-right: 2rem;
    }

    .cell-thumbnail {
      min-height: 7rem;
      min-width: 7rem;
    }
  }

  &.cell-size-mode-small, &.cell-size-mode-auto {
    .cell-group-cells {
      max-width: 4*9rem + 3*2rem;
    }
    .grid-cell {
      max-width: 9rem;
    }
  }

  &.cell-size-mode-medium {
    .cell-group-cells {
      max-width: 3*15rem + 2*2rem;
    }
    .grid-cell {
      max-width: 15rem;
    }
  }

  &.cell-size-mode-large {
    .cell-group-cells {
      max-width: 2*20rem + 4*2rem;
    }
    .grid-cell {
      max-width: 20rem;
    }
  }

  @media #{$wideWindow} {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    .details-pane {
      width: 40rem;

      .details-slideshow {
        height: 25rem;
      }
    }

    //cell count/width
    @media (min-width: 1001px) {
      &.cell-size-mode-small .grid-cell, &.cell-size-mode-auto .grid-cell { @include gridCell(4); }
      &.cell-size-mode-medium .grid-cell { @include gridCell(3); }
      &.cell-size-mode-large .grid-cell { @include gridCell(2); }
    }
    @media (min-width: 801px) and (max-width: 1000px) {
      &.cell-size-mode-small .grid-cell, &.cell-size-mode-auto .grid-cell { @include gridCell(3); }
      &.cell-size-mode-medium .grid-cell { @include gridCell(2); }
      &.cell-size-mode-large .grid-cell { @include gridCell(1); }
    }
  }

  @media #{$narrowWindow} {
    .details-pane {
      width: calc(100% - 5rem) ;

      .details-slideshow {
        height: 20rem;
      }
      .details-header {
        .cell-close-details {
          width: 3.5rem;
          height: 3.5rem;
          padding: 1rem;
          opacity: 0.3;
          cursor: pointer;
          font-size: 1.5rem;

          &:hover {
            color: rgba(0, 0, 0, 0.8);
          }
          &::after {
            content: "✕";
          }
        }
      }
      .details-notes {
        .details-note {
        }
      }
    }

    //cell count/width
    @media (min-width: 701px) and (max-width: 800px) {
      &.cell-size-mode-small .grid-cell, &.cell-size-mode-auto .grid-cell { @include gridCell(4); }
      &.cell-size-mode-medium .grid-cell { @include gridCell(3); }
      &.cell-size-mode-large .grid-cell { @include gridCell(2); }
    }
    @media (min-width: 501px) and (max-width: 700px) {
      &.cell-size-mode-small .grid-cell, &.cell-size-mode-auto .grid-cell { @include gridCell(3); }
      &.cell-size-mode-medium .grid-cell { @include gridCell(2); }
      &.cell-size-mode-large .grid-cell { @include gridCell(1); }
    }
    @media (min-width: 351px) and (max-width: 500px) {
      &.cell-size-mode-small .grid-cell, &.cell-size-mode-auto .grid-cell { @include gridCell(2); }
      &.cell-size-mode-medium .grid-cell, &.cell-size-mode-large .grid-cell { @include gridCell(1); }
    }
    @media (max-width: 350px) {
      &.cell-size-mode-small .grid-cell, &.cell-size-mode-auto .grid-cell { @include gridCell(1); }
      &.cell-size-mode-medium .grid-cell, &.cell-size-mode-large .grid-cell { @include gridCell(1); }
    }
  }

  @media #{$mobileLandscape} and (orientation: landscape) {
    .details-pane {
      flex-direction: row;

      .constraints-container {
        padding: 1rem;
        padding-left: 0;
        width: 15rem;
        left: 0px;
      }

      .details-slideshow {
        position: absolute;
        width: calc(100% - 15rem);
        height: calc(100% - 4rem);
        right: 0px;
        bottom: 0px;
      }
      .details-notes {

        .details-note {
          width: 11rem;
        }
      }
    }
  }
}
