* {
  box-sizing: border-box;
}

.button, input, .form-input, .dropdown.selection {
  outline: none;
  -webkit-appearance: none;
}

.button, .text-input input, .text-input textarea, textarea, .date-input input {
  border-radius: 0px;
  padding: 0.7rem 1rem;
  border: none;
  font-size: 1rem;
}

.label, label {
  padding: 0.5rem 1rem;
  display: block;
}

.form-input {
  display: inline-block;
  vertical-align: bottom;
}

.text-input input, .button {
  border: 0px solid #ddd;
}

.date-input input:focus, .text-input input:focus {
  border: 0px solid #cccccc;
}

.text-input input, .text-input textarea, .date-input input, .date-input textarea {
  background-color: #F5F5F5;
}

.button {
  background-color: black;
  color: white;
}

.radio-input input {
  background-color: #eeeeee;
}
.radio-input input:checked {
  background-color: #999;
  border-color: #999;
}
.radio-input input:not(:checked):hover {
  background-color: #e6e6e6;
}