$mobileDevice: 'only screen and (max-device-width: 1024px)';
$desktopDevice: 'print, (min-device-width: 1025px)';

$narrowWindow: 'only screen and (max-width: 800px)';
$wideWindow: 'print, (min-width: 801px)';

$mobileLandscape: '#{$mobileDevice} and (orientation: landscape)';

$padding: 2rem;
$border: 1px solid #e5e5e5;

$zNavigation: 1;
$zPopup: 2;
$zSlideshow: 3;
