.slideshow {
  width: 100%;
  height: 100%;
}
.slideshow.is-fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0px;
  right: 0px;
  z-index: 3;
}
.slideshow .slideshow-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.slideshow .slideshow-content .slideshow-media, .slideshow .slideshow-content .slideshow-slides {
  height: 100%;
  width: 100%;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium, .slideshow .slideshow-content .slideshow-media .slideshow-slide, .slideshow .slideshow-content .slideshow-slides .slideshow-medium, .slideshow .slideshow-content .slideshow-slides .slideshow-slide {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: opacity 0.5s;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium:not(.active), .slideshow .slideshow-content .slideshow-media .slideshow-slide:not(.active), .slideshow .slideshow-content .slideshow-slides .slideshow-medium:not(.active), .slideshow .slideshow-content .slideshow-slides .slideshow-slide:not(.active) {
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium {
  background-position: center;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium img {
  height: 100%;
  width: 100%;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium.size-mode-contain img {
  object-fit: contain;
}
.slideshow .slideshow-content .slideshow-media .slideshow-medium.size-mode-cover img {
  object-fit: cover;
}
.slideshow .slideshow-content .slideshow-controls {
  position: absolute;
  display: flex;
}
.slideshow .slideshow-content .slideshow-controls.show-background {
  background-color: rgba(0, 0, 0, 0.3);
}
.slideshow .slideshow-content .slideshow-controls.position-right, .slideshow .slideshow-content .slideshow-controls.position-left {
  top: 0px;
  width: 3rem;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}
.slideshow .slideshow-content .slideshow-controls.position-right .slideshow-dots, .slideshow .slideshow-content .slideshow-controls.position-left .slideshow-dots {
  flex-direction: column;
  padding: 0.5rem 0;
}
.slideshow .slideshow-content .slideshow-controls.position-right .slideshow-prev, .slideshow .slideshow-content .slideshow-controls.position-left .slideshow-prev {
  padding-top: 0.7rem;
  padding-bottom: 0.4rem;
}
.slideshow .slideshow-content .slideshow-controls.position-right .slideshow-prev .arrow, .slideshow .slideshow-content .slideshow-controls.position-left .slideshow-prev .arrow {
  transform: rotate(-135deg);
}
.slideshow .slideshow-content .slideshow-controls.position-right .slideshow-next, .slideshow .slideshow-content .slideshow-controls.position-left .slideshow-next {
  padding-top: 0.4rem;
  padding-bottom: 0.7rem;
}
.slideshow .slideshow-content .slideshow-controls.position-right .slideshow-next .arrow, .slideshow .slideshow-content .slideshow-controls.position-left .slideshow-next .arrow {
  transform: rotate(45deg);
}
.slideshow .slideshow-content .slideshow-controls.position-right {
  right: 0px;
}
.slideshow .slideshow-content .slideshow-controls.position-left {
  left: 0px;
}
.slideshow .slideshow-content .slideshow-controls.position-top, .slideshow .slideshow-content .slideshow-controls.position-bottom {
  left: 0px;
  height: 3rem;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.slideshow .slideshow-content .slideshow-controls.position-top .slideshow-dots, .slideshow .slideshow-content .slideshow-controls.position-bottom .slideshow-dots {
  padding: 0 0.5rem;
}
.slideshow .slideshow-content .slideshow-controls.position-top .slideshow-prev, .slideshow .slideshow-content .slideshow-controls.position-bottom .slideshow-prev {
  padding-left: 0.7rem;
  padding-right: 0.4rem;
}
.slideshow .slideshow-content .slideshow-controls.position-top .slideshow-prev .arrow, .slideshow .slideshow-content .slideshow-controls.position-bottom .slideshow-prev .arrow {
  transform: rotate(135deg);
}
.slideshow .slideshow-content .slideshow-controls.position-top .slideshow-next, .slideshow .slideshow-content .slideshow-controls.position-bottom .slideshow-next {
  padding-left: 0.4rem;
  padding-right: 0.7rem;
}
.slideshow .slideshow-content .slideshow-controls.position-top .slideshow-next .arrow, .slideshow .slideshow-content .slideshow-controls.position-bottom .slideshow-next .arrow {
  transform: rotate(-45deg);
}
.slideshow .slideshow-content .slideshow-controls.position-top {
  top: 15px;
}
.slideshow .slideshow-content .slideshow-controls.position-bottom {
  bottom: 15px;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-dots {
  position: relative;
  display: flex;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-dots .slideshow-dot-container {
  padding: 0.4rem;
  cursor: pointer;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-dots .slideshow-dot-container:hover .slideshow-dot {
  background-color: rgba(255, 255, 255, 0.3);
}
.slideshow .slideshow-content .slideshow-controls .slideshow-dots .slideshow-dot-container.active .slideshow-dot {
  background-color: white;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-dots .slideshow-dot-container .slideshow-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 2px solid white;
  margin: auto;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-control-button {
  cursor: pointer;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-control-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.slideshow .slideshow-content .slideshow-controls .slideshow-control-button .arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  width: 0;
  height: 0;
  padding: 5px;
  margin: auto;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-prev, .slideshow .slideshow-content .slideshow-controls .slideshow-next {
  height: 2rem;
  display: flex;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-toggle-fullscreen {
  margin-top: 1.5rem;
  padding: 0.9rem;
  width: 3rem;
  height: 3rem;
}
.slideshow .slideshow-content .slideshow-controls .slideshow-toggle-fullscreen img {
  height: 100%;
  width: 100%;
}
@media only screen and (max-device-width: 1024px) {
  .slideshow.is-fullscreen {
    width: calc(100% - 5rem);
  }
}