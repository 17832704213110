.radio-input .radio {
  display: flex;
}
.radio-input .radio:not(:last-child) {
  margin-bottom: 0.3rem;
}
.radio-input .radio input {
  flex-shrink: 0;
  margin: 1px;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.radio-input .radio label {
  display: inline-block;
  padding: 0;
  padding-left: 1rem;
}
.radio-input .radio label:hover:not(:focus) {
  opacity: 0.8;
}