@import 'styles/index.scss';

.footer {
  margin-top: 1.7rem;
  margin-bottom: 2rem;
  margin-left: 2rem;

  .careers-link, .private-policy-link, .tracking-options, .sf-credit {
    font-size: 0.7rem;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 0.5rem;
    color: rgb(100, 100, 100);

    &:hover {
      color: rgb(50, 50, 50);
    }

    a {
      color: inherit;
    }
  }
}