.footer {
  margin-top: 1.7rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
.footer .careers-link, .footer .private-policy-link, .footer .tracking-options, .footer .sf-credit {
  font-size: 0.7rem;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 0.5rem;
  color: rgb(100, 100, 100);
}
.footer .careers-link:hover, .footer .private-policy-link:hover, .footer .tracking-options:hover, .footer .sf-credit:hover {
  color: rgb(50, 50, 50);
}
.footer .careers-link a, .footer .private-policy-link a, .footer .tracking-options a, .footer .sf-credit a {
  color: inherit;
}