.button {
  cursor: pointer;
}
.button:hover {
  opacity: 0.7;
}

.theme-light .button, .theme-light .text-input input {
  font-size: 0.9rem;
}
.theme-light .button, .theme-light .text-input input, .theme-light .dropdown.selection > .text, .theme-light .dropdown.selection .menu {
  border: 1px solid #ddd;
}
.theme-light .text-input input {
  width: 100%;
}
.theme-light label, .theme-light .label {
  padding: 0.4rem 1rem;
  padding-top: 0.6rem;
}