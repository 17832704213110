.register-employee {
  display: flex;
  height: 100%;
}
.register-employee .register-employee-content-container {
  margin: auto;
  text-align: center;
}
.register-employee .register-employee-content-container .register-employee-message {
  color: rgb(119, 152, 173);
  margin-bottom: 2rem;
}
.register-employee .register-employee-content-container .redirect-message .click-here-link {
  opacity: 0.8;
}
@media only screen and (max-width: 800px) {
  .register-employee {
    width: calc(100% - 2rem);
  }
  .register-employee .register-employee-message {
    font-size: 1.2rem;
  }
}
@media print, (min-width: 801px) {
  .register-employee {
    width: calc(100% - 5rem);
  }
  .register-employee .register-employee-message {
    font-size: 1.8rem;
  }
}