$padding: 1rem;
$textBoxPadding: 0.7rem 1rem;
$borderRadius: 0px;
$hoverOpacity: 0.8;
$inputWidth: 15rem;

$lightInputBackgroundColor: #F5F5F5;
$lightBorder: 0px solid #ddd;
$lightFocusedBorder: 0px solid #cccccc;
$lightBoxShadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
