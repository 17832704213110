@import 'styles/index.scss';

.page-section {
  position: relative;
  transition: opacity 0.6s;
  // height: 100%;

  &.layout-text {
    opacity: 0.75;
  }

  .page-section-logo-text {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: $padding + 1.5rem;
    // left: 0px;
    transition: opacity 1s;

    .logo-page-title {
      margin-left: 0.25em;
      opacity: 0.6;
    }
  }

  //custom page layouts
  //press and awards
  .press, .awards {
    .press-page-title, .awards-page-title {
      letter-spacing: 3px;
      font-size: 2rem;
      text-transform: uppercase;
    }
    .press-page-footer, .awards-page-footer {
      .email {
        line-height: 1.6;
        color: rgb(119, 152, 173);

        &:hover {
          color: rgb(80, 112, 133);
        }
      }
    }
  }

  .project-stories, .showrooms {
    .tiles-container {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .tile {
        flex-grow: 1;
        display: flex;
        position: relative;
        overflow: hidden;

        &:not(:last-child) {
          margin-bottom: 2rem;
        }

        .tile-title {
          margin: auto;
          color: #FFF;
          text-align: center;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

          & > * {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
          }

          .tile-title-state, .tile-title-coming-soon {
            font-size: 50%;
            opacity: 0.7;
          }
        }
        .tile-image-container {
          position: absolute;
          height: 100%;
          width: 100%;
          overflow: hidden;
          z-index: -1;

          .tile-image {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: filter ease 0.5s;
          }
        }
      }
    }
  }
  &:not(:last-child) {
    border-bottom: $border;
  }

  @media #{$wideWindow} {
    // scroll-snap-align: start;

    &:not(.heightAuto) {
      height: 100%;
      min-height: 48rem;
    }
    &:not(:first-child).layout-text-left-grid-right {
      .page-section-logo-text {
        display: none;
      }
    }

    &.text-style-branding {
      .page-section-text .text-content {
        max-width: 25rem;
        font-size: 1rem;
      }
    }
    &.text-style-details:not(.layout-text):not(.layout-media-top-text-bottom) {
      .page-section-text .text-content {
        max-width: 25rem;
        padding-left: 2rem;
      }
    }
    &.text-style-standard:not(.layout-text):not(.layout-media-top-text-bottom) {
      .page-section-text {
        font-size: 1.8rem;

        .text-content {
          padding-left: 2rem;
        }
      }
    }

    &.heightAuto {
      &.layout-text {
        padding-bottom: $padding * 5;
      }
    }
    &:not(.heightAuto) {
      &.layout-text {
        min-height: 100%;
      }
    }
    &.layout-text {
      padding: $padding;
      height: auto;

      &:not(.hideLogo) {
        &:first-child {
          padding-bottom: 7rem;
        }
      }
      &.hideLogo {
        &:first-child {
          padding-bottom: 2rem;
        }
        .page-section-logo-text {
          display: none;
        }
      }

      .page-section-text {
        font-size: 1.8rem;
        height: 100%;

        .text-item-link {
          font-size: 0.8rem;
        }
      }
    }
    &.layout-text-left {
      .page-section-text {
        height: 100%;
        width: 50%;
      }
    }
    &.layout-text-left-media-right {
      .page-section-text {
        padding-right: 4rem;
        // padding-bottom: 5rem;
      }
      .media-container {
        .page-section-medium:not(.orientation-vertical) {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
      .vertical-split {
        &.double-media {

        }

        &:not(.double-media) {
          .page-section-medium {
            &.orientation-square {
              .medium-image {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right center;
              }
            }
            &.orientation-horizontal {
              .medium-image {
                height: auto;
                background-image: none !important;

                img {
                  opacity: 1;
                  height: auto;
                  position: relative;
                }
              }
            }
          }
        }
      }
    }
    &.layout-text-left-grid-right {
      .page-section-text {
        padding-right: 4rem;
      }
      .page-section-grid {
        padding-left: 0;
      }
    }
    &.layout-media-top-text-bottom {
      .horizontal-flex-split {
        height: 100%;
        display: flex;
        flex-direction: column;

        .page-section-medium {
          height: auto;
          flex: 1;
        }
        .page-section-text {
          padding-left: $padding;

          .text-content {
            font-size: 1rem;
          }
        }
      }
    }
    &.layout-media {
      padding-top: $padding;

      video {
        object-fit: cover;
      }
    }
    &.layout-accordion-with-media{
      min-height: 45rem;

      .accordion-container{
        display: flex;
        position: relative;
        align-items: flex-start;
        padding: 4rem;

        .accordion{
          width: 25rem;
          margin-bottom: 4rem;
        }
      }
      .media-container {
        height: 100vh !important;
        min-height: 45rem;

        .page-section-medium {
          &.orientation-square {
            .medium-image {
              background-size: contain;
              background-repeat: no-repeat;
              background-position: right center;
            }
          }
          &.orientation-horizontal {
            .medium-image {
              height: auto;
              background-image: none !important;

              img {
                opacity: 1;
                height: auto;
                position: relative;
              }
            }
          }
        }
      }
    }
    &.layout-double-media {
      padding-top: $padding;

      .media-container {
        &.left {
          .medium-image {
            background-position: right center!important;
          }
        }
        &.right {
          margin-left: 1px;

          .medium-image {
            background-position: left center!important;
          }
        }
      }
      .page-section-medium {
        &.orientation-square, &.orientation-vertical {
          .medium-image {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right center;
          }
        }
      }
    }

    .text-item-link {
      font-size: 0.8rem;
      letter-spacing: 0.1rem;
    }

    .page-section-logo-text {
      font-size: 1rem;
      letter-spacing: 0.1rem;
    }

    .long-paragraph {
      font-size: 1rem;
      width: 75%;
    }

    .vertical-split {
      height: 100%;
      display: flex;

      .page-section-logo-text {
        padding-left: 2rem;
      }

      .page-section-text, .media-container, .page-section-grid, .accordion-container {
        height: 100%;
        width: 50%;
      }
    }

    //custom page layouts
    //press
    .press, .awards {
      .press-page-title, .press-page-footer,
      .awards-page-title, .awards-page-footer {
        padding: $padding 2rem;
      }
      .press-quotes, .awards {
        margin-bottom: 4rem;

        .page-section-text {
          padding: $padding 2rem;
          padding-right: 10vw;
          width: 50%;
          display: inline-block;
        }
      }
    }

    .project-stories, .showrooms {
      .tiles-container {
        padding: 0 $padding;

        .tile {
          height: 100%;
          min-height: 48rem;

          &:hover {
            cursor: pointer;

            .tile-title {
              opacity: 0.85;
            }
            .tile-image-container {
              .tile-image {
                filter: brightness(75%) !important;
              }
            }
          }

          .tile-title {
            font-size: 5.5vw;
            letter-spacing: 0.4vw;
            padding: 0 $padding * 2;
          }
          .tile-image-container {
            .tile-image {
              filter: brightness(50%);
            }
          }
        }
      }
    }
  }
  @media #{$narrowWindow} {
    padding: 1rem;

    .page-section-logo-text {
      // left: 1rem;
    }

    &:first-child:not(.layout-media):not(.layout-media-top-text-bottom):not(.layout-text) {
      min-height: 100%;
    }
    &:not(:first-child) {
      .page-section-logo-text {
        display: none;
      }
    }
    &.text-style-branding {
      .page-section-text .text-content {
        font-size: 1rem;
      }
    }
    &.text-style-details {
      .page-section-text .text-content .long-paragraph {
        font-size: 1rem;
      }
      .page-section-text .text-content .text-item-link {
        font-size: 0.8rem;
      }
    }

    &.layout-text-left-media-right, &.layout-accordion-with-media {
      .vertical-split {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &.layout-accordion-with-media {
      .page-section-logo-text {
        display: none;
      }
      .accordion {
        margin-top: 1rem;
      }
    }
    &.layout-media {
      // padding-bottom: $padding;
    }
    &.layout-text {
      &:first-child {
        padding-bottom: 5rem;
      }
      &.hideLogo {
        &:first-child {
          padding-bottom: 2rem;
        }
        .page-section-logo-text {
          display: none;
        }
      }

      .page-section-text {
        font-size: 1.8rem;
        height: 100%;

        @media #{$mobileDevice} {
          font-size: 1.2rem;
        }
      }
    }

    &:not(.layout-text) .page-section-logo-text {
      position: relative;
      bottom: 0px;
      margin-top: 1rem;
    }
    .page-section-medium {
      margin-bottom: 1px;
    }
    .page-section-text, .page-section-medium {
      display: block;
      width: 100%;
    }

    //custom page layouts
    //press
    .press, .awards {
      .press-page-title, .press-page-footer,
      .awards-page-title, .awards-page-footer {
        margin-bottom: $padding;
      }
      .press-quotes, .awards {
        margin-bottom: 4rem;

        .page-section-text {
          margin-bottom: $padding;
        }
      }
    }

    .project-stories, .showrooms {
      .tiles-container {
        .tile {
          height: 40vw;
          min-height: 20rem;

          .tile-title {
            font-size: 8vw;
            letter-spacing: 0.75vw;
            padding: 0 $padding / 2;
          }
          .tile-image-container {
            .tile-image {
              filter: brightness(60%);
            }
          }
        }
      }
    }
  }
}
