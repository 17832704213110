#root {
  overflow-x: hidden;
}
#root:not(.landing-page) {
  height: 100%;
  min-height: 100%;
}

.app {
  position: relative;
  left: 0px;
}
.app:not(.landing-page) {
  height: 100%;
  min-height: 100%;
}
.app, .app .navigation-tray {
  transition: left 0.4s;
}
.app .app-content {
  height: 100%;
  z-index: 0;
}
.app .app-404 {
  width: 100%;
  height: 100%;
}
.app .app-404 .text-404 {
  text-align: center;
  position: relative;
  top: calc(50% - 0.7rem);
}
@media print, (min-width: 801px) {
  .app.showing-navigation .app-navigation {
    width: 50%;
  }
  .app.showing-navigation .app-navigation .navigation-tray {
    left: 0px;
  }
}
@media print, print and (min-width: 801px), print and (min-width: 801px), (min-width: 801px) and (min-width: 801px) {
  .app:not(.showing-navigation) .app-navigation {
    width: 5rem;
  }
}
@media only screen and (min-width: 801px) and (max-width: 800px) {
  .app:not(.showing-navigation) .app-navigation {
    width: 100%;
    height: 4rem;
  }
}
@media print, (min-width: 801px) {
  .app:not(.showing-navigation) .app-navigation .list-item-target .list-item-target-text {
    opacity: 0;
  }
  .app:not(.showing-navigation) .app-navigation .navigation-tray {
    left: 0;
  }
  .app .app-content {
    padding-left: 5rem;
  }
  .app .gdpr-banner {
    right: 0;
    left: auto;
  }
}
@media only screen and (max-width: 800px) {
  .app.showing-navigation .app-navigation {
    width: 100%;
  }
  .app.showing-navigation .app-navigation .navigation-tray {
    left: 0px;
  }
}
@media only screen and (max-width: 800px) and (min-width: 801px) {
  .app:not(.showing-navigation) .app-navigation {
    width: 5rem;
  }
}
@media only screen and (max-width: 800px) and (max-width: 800px) {
  .app:not(.showing-navigation) .app-navigation {
    width: 100%;
    height: 4rem;
  }
}
@media only screen and (max-width: 800px) {
  .app:not(.showing-navigation) .app-navigation .list-item-target .list-item-target-text {
    opacity: 0;
  }
  .app:not(.showing-navigation) .app-navigation .navigation-tray {
    left: 0;
  }
  .app .app-content {
    padding-top: 4rem;
  }
}
@media print {
  @page {
    margin: 0;
    orientation: landscape;
    size: 11in 8.5in;
  }
  html, body, .app-content, .app-page {
    height: auto !important;
    min-height: auto !important;
  }
  .app .app-navigation {
    display: none;
  }
  .app .app-content {
    padding-left: 0;
  }
  .app .app-content .app-page .page-sections {
    overflow: inherit !important;
  }
  .app .app-content .app-page .page-sections .page-section {
    page-break-after: always;
    border-bottom: none;
  }
  .app .app-content .app-page .page-sections .page-section.layout-text-left-grid-right .grid-cell {
    width: 8rem;
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .app .app-content .app-page .page-sections .page-section.layout-text-left-grid-right .cell-group:not(:first-child) .grid-cell {
    margin-top: 3rem;
  }
  .app .app-content .app-page .page-sections .page-section:not(.layout-text-left-grid-right) {
    height: 8.5in;
  }
  .app .app-content .app-page .page-sections .page-section .page-section-logo-text {
    display: none;
  }
}
.hb-cv-message {
  line-height: 1.4;
  color: #666;
}
.hb-cv-message a {
  color: #333;
  text-decoration: underline;
}
@media screen and (min-width: 801px) {
  .hb-cv-message {
    max-width: 42rem;
    padding: 5rem;
  }
}
@media screen and (max-width: 800px) {
  .hb-cv-message {
    font-size: 0.8rem;
  }
}

.hb-cv-message {
  line-height: 1.4;
  color: #666;
}
.hb-cv-message a {
  display: inline-block;
  vertical-align: middle;
}
@media screen and (min-width: 801px) {
  .hb-cv-message {
    max-width: 44rem;
    padding: 5rem 10rem;
    font-size: 2rem;
  }
  .hb-cv-message span {
    font-size: 1.7rem;
  }
  .hb-cv-message img {
    width: 2.5rem;
  }
}
@media screen and (max-width: 800px) {
  .hb-cv-message {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .hb-cv-message span {
    font-size: 1rem;
  }
  .hb-cv-message img {
    width: 2.5rem;
  }
}

.popup .popup-window {
  border-radius: 0px;
  box-sizing: border-box;
  position: relative;
  height: auto;
}
.popup .popup-window .popup-close-button {
  cursor: pointer;
  width: 2rem !important;
  height: 2rem !important;
  display: block;
  opacity: 0.6;
}
@media screen and (min-width: 801px) {
  .popup .popup-window .close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .popup .popup-window {
    padding: 2rem;
    width: calc(100% - 2rem) !important;
  }
  .popup .popup-window .close-button {
    margin-left: auto;
    position: relative;
    top: -1rem;
    left: 0.5rem;
  }
}
.popup.type-fitted .popup-window {
  background-color: #eee;
}
@media screen and (min-width: 801px) {
  .popup.type-fitted .popup-window .popup-content {
    padding: 1.5rem 2.5rem;
  }
}
.popup.type-fitted .popup-window .popup-content .inquiry-popup {
  text-align: center;
}
.popup.type-fitted .popup-window .popup-content .inquiry-popup .inquiry-popup-tagline {
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(119, 152, 173);
  margin-bottom: 1rem;
}
.popup.type-fitted .popup-window .popup-content .inquiry-popup .inquiry-popup-message {
  margin-bottom: 2rem;
}
.popup.type-corner .popup-window {
  background-color: #eee;
}
@media screen and (min-width: 801px) {
  .popup.type-corner .popup-window {
    width: 22rem;
  }
  .popup.type-corner .popup-window.position-left {
    left: 7rem !important;
  }
}
.popup.type-corner .popup-window .popup-content {
  font-size: 0.8rem;
  line-height: 1.4;
}
@media screen and (min-width: 801px) {
  .popup.type-corner .popup-window .popup-content {
    padding: 1.5rem 2.5rem;
  }
}
.popup.type-corner .popup-window .popup-content .subscribe-popup .subscribe-popup-tagline, .popup.type-corner .popup-window .popup-content .subscribe-popup .subscribe-popup-message {
  margin-bottom: 1em;
}
.popup.type-corner .popup-window .popup-content .subscribe-popup .subscribe-popup-tagline {
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(119, 152, 173);
}

.subscribe-form-container {
  height: 2.8rem;
  display: flex;
}
.subscribe-form-container iframe {
  border: none;
  width: 100%;
}