@import 'styles/index.scss';

.landing-page {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .landing-page-frame {
    border-bottom: $border;
  }

  .landing-page-frame, .slideshow-slide {
    display: flex;

    .text-container, .media-container {
      display: flex;
      position: relative;
    }

    .text-container {
      flex-direction: column;
      line-height: 1.4;
      flex: 1;

      .text-item {
        width: 100%;

        a, .discuss-a-project {
          color: rgb(119, 152, 173);
          cursor: pointer;

          &:hover {
            color: rgb(80, 112, 133);
          }
        }

        &.heading {
          font-weight: bold;
          color: rgb(119, 152, 173);
        }
      }

      &.title-text {
        @keyframes fadeInAnimation {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        .company-name, .tag-line, .secondary-tag-line {
          opacity: 0;
          animation: fadeInAnimation 1s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
        .company-name, .tag-line {
          animation-delay: 0.25s;
        }
        .secondary-tag-line {
          animation-delay: 1s;
        }

        .company-name {
          span:nth-child(2) {
            opacity: 0.7;
          }
        }
        .tag-line {
          font-weight: bold;
        }
        .secondary-tag-line {
          font-weight: bold;
        }
      }
    }
    .media-container {
      .image-container {
        height: 100%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .scroll-arrow-position-container {
    position: fixed;
    display: flex;

    .left-container {
      display: flex;
      justify-content: center;

      .scroll-arrow-container {
        height: 2rem;
        width: 6rem;
        display: flex;
        transition: visibility 0s, opacity 1s;

        &.hidden {
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
        &.visible {
          visibility: visible;
          opacity: 1;
        }

        .arrow {
          border: solid #bbb;
          border-width: 0 2px 2px 0;
          width: 0;
          height: 0;
          padding: 5px;
          margin: auto;
          transform: rotate(45deg);
          translate: 0 -2.5px;
        }
      }
    }
  }

  @media #{$narrowWindow} {
    .landing-page-frame {
      height: 100vh;
      height: 100lvh;
      min-height: 100vw;
    }

    .landing-page-frame, .slideshow-slide {
      flex-direction: column-reverse;

      &:not(:last-child) {
        padding-bottom: 3rem;
      }

      .text-container {
        padding: 10vw;

        &.align-center {
          align-items: center;
        }

        &.title-text {
          .company-name {
            font-size: 7.5vw;
            letter-spacing: 1.8vw;
            margin-bottom: 3vw;
          }
          .tag-line {
            font-size: 3.5vw;
            margin-bottom: 9vw;
            letter-spacing: 1vw;
          }
          .secondary-tag-line {
            font-size: 2vw;
            letter-spacing: 0.5vw;
            text-align: center;
          }
        }
        .text-item {
          max-width: 60vw;
          font-size: 3.75vw;

          &.heading {
            font-size: 5vw;
          }
          &:not(:last-child) {
            margin-bottom: 1.5vw;
          }
        }
      }
      .media-container {
        height: 50vh;
        height: 50lvh;
      }
    }

    .scroll-arrow-position-container {
      width: 100%;
      bottom: 5vw;

      .left-container {
        flex: 1;
      }
    }
  }
  @media #{$wideWindow} {
    .landing-page-frame {
      height: 100vh;
      height: 100lvh;
    }

    .landing-page-frame, .slideshow-slide {
      flex-direction: row;

      .text-container {
        min-width: 50vh;
        min-width: 50lvh;
        padding: 3vw;
        align-items: center;
        justify-content: center;

        &.title-text {
          .company-name {
            font-size: 2.25vw;
            margin-bottom: 1rem;
            letter-spacing: 0.75vw;
          }
          .tag-line {
            font-size: 1vw;
            margin-bottom: 15%;
            letter-spacing: 0.5vw;
          }
          .secondary-tag-line {
            font-size: 0.75vw;
            letter-spacing: 0.25vw;
          }
        }
        .text-item {
          max-width: 25rem;
          font-size: calc(8px + 0.5vw);

          &.heading {
            font-size: calc(8px + 0.75vw);
          }
          &:not(:last-child) {
            margin-bottom: 0.5vw;
          }
        }
      }
      .media-container {
        width: 100vh;
        width: 100lvh;
      }
    }

    .scroll-arrow-position-container {
      width: calc(100% - 5rem);
      bottom: 5rem;
      pointer-events: none;

      .left-container {
        min-width: 50vh;
        min-width: 50lvh;
        padding: 0 3vw;
        flex: 1;
        pointer-events: all;
      }
      .right-container {
        width: 100vh;
        width: 100lvh;
      }

      .scroll-arrow-container {
        cursor: pointer;
        left: calc(25% + 5rem);

        &:hover {
          .arrow {
            border-color:#777;
          }
        }
      }
    }
  }
}
