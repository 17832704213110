@import 'styles/index.scss';

.accordion {
  .accordion-title {
    letter-spacing: 3px;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  .accordion-description {
    font-size: 1rem;
    margin-bottom: 2rem;
    // padding-left: 2rem;

    a[href^="mailto:"]{
      cursor: pointer;

      &.blue {
        color: rgb(119, 152, 173);

        &:hover {
          color: rgb(80, 112, 133);
        }
      }
      &:not(.blue) {
        color: rgb(180, 180, 180);

        &:hover {
          color: rgb(139, 138, 135);
        }
      }
    }
  }

  .accordion-section{
    &:not(.is-expanded) {
      .accordion-section-content{
        display: none;
        visibility: hidden;
      }
    }
    &.is-expanded{
      .accordion-section-title{
        color: #7798ad;
      }
    }

    .accordion-section-title {
      padding: .75rem 0rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.3rem;
      font-size: 1rem;
      border-bottom: 1px solid #bbb;
      cursor: pointer;
      user-select: none;

      &:hover{
        opacity: 0.6;
      }
    }

    .accordion-section-content{
      margin-bottom: 2rem;
      padding-left: 1rem;

      .accordion-text-item{
        margin-bottom: 1rem;

        b {
          font-weight: bold;
          opacity: 0.9;
        }
      }
    }
  }
  .accordion-text-item-link {
    cursor: pointer;
    color: rgb(180, 180, 180);

    &:hover {
      color: rgb(139, 138, 135);
    }

    &.next-page {
      margin-top: 3em;
      text-transform: uppercase;
    }

    &.blue {
      color: rgb(119, 152, 173);

      &:hover {
        color: rgb(80, 112, 133);
      }
    }
    &.dark-gray {
      color: rgb(70, 70, 70);
      font-size: 1rem;

      &:hover {
        color: rgb(140, 140, 140);
      }
    }

    &.next-page {
      text-transform: uppercase;
      letter-spacing: 0.1rem;

    }
  }
  .next-page-container{
    margin-top: 5em;
    padding-left: 2rem;
  }
}
