@import "lib/styles/import.scss";

.text-input {
  width: $inputWidth;

  input {
    width: 100%;

    &:hover:not(:focus) {
      opacity: $hoverOpacity;
    }
  }
}
