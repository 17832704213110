@charset "UTF-8";
.page-section.layout-text-left-grid-right:first-child .page-section-grid {
  margin-bottom: 2rem;
}

.page-section-grid {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.page-section-grid:not(.cell-size-mode-auto) .cell-thumbnail img {
  width: 100%;
}
.page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail img {
  display: block;
}
.page-section-grid:not(.all-cells-are-loaded) .grid-cell, .page-section-grid:not(.all-cells-are-loaded) .cell-group-title {
  opacity: 0;
}
.page-section-grid .grid-cell-details {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.page-section-grid .grid-cell-details.is-visible {
  animation: show 0.5s;
}
.page-section-grid .grid-cell-details:not(.is-visible) {
  opacity: 0;
  animation: hide 0.5s;
}
.page-section-grid .grid-cell-details .details-background {
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  position: relative;
}
.page-section-grid .grid-cell-details .details-background .details-pane {
  height: 100%;
  background-color: white;
  color: #000;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 0px;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-header {
  display: flex;
  border-bottom: 1px solid #ddd;
  min-height: 4rem;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-header .cell-title {
  font-size: 1.5rem;
  text-transform: lowercase;
  padding: 1rem 2rem;
  width: 100%;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-content {
  overflow: auto;
  flex-grow: 1;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-content .constraints-container {
  max-height: 9rem;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-content .details-notes {
  display: inline-block;
  margin: 1rem 0rem;
  padding-left: 3rem;
  overflow-wrap: break-word;
}
.page-section-grid .grid-cell-details .details-background .details-pane .details-content .details-notes .details-note {
  text-transform: lowercase;
  padding: 0.2rem 0rem;
}
.page-section-grid .cell-groups .cell-group .cell-group-title {
  padding: 0.5rem 0;
  font-weight: bold;
  font-size: 0.9rem;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell {
  display: inline-block;
  vertical-align: bottom;
  text-align: left;
  position: relative;
  margin-bottom: 2rem;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell.has-details {
  cursor: pointer;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell.has-details:hover .cell-title {
  text-decoration: underline;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell .cell-title {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  height: 2rem;
  text-transform: lowercase;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell .cell-thumbnail.is-loaded img {
  max-width: 100%;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell .cell-thumbnail:not(.is-loaded) img {
  position: absolute;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell .cell-thumbnail.has-error img {
  display: none;
}
.page-section-grid .cell-groups .cell-group .cell-group-cells .grid-cell .cell-thumbnail.has-border img {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.page-section-grid.cell-size-mode-small .cell-group-cells, .page-section-grid.cell-size-mode-auto .cell-group-cells {
  max-width: 42rem;
}
.page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
  max-width: 9rem;
}
.page-section-grid.cell-size-mode-medium .cell-group-cells {
  max-width: 49rem;
}
.page-section-grid.cell-size-mode-medium .grid-cell {
  max-width: 15rem;
}
.page-section-grid.cell-size-mode-large .cell-group-cells {
  max-width: 48rem;
}
.page-section-grid.cell-size-mode-large .grid-cell {
  max-width: 20rem;
}
@media print, (min-width: 801px) {
  .page-section-grid {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
  }
  .page-section-grid .details-pane {
    width: 40rem;
  }
  .page-section-grid .details-pane .details-slideshow {
    height: 25rem;
  }
}
@media print and (min-width: 1001px), (min-width: 801px) and (min-width: 1001px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 4 - 3 * 2rem / 4);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(4n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(4n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell {
    width: calc(100% / 3 - 2 * 2rem / 3);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(3n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 2 - 1 * 2rem / 2);
  }
  .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(2n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media print and (min-width: 801px) and (max-width: 1000px), (min-width: 801px) and (min-width: 801px) and (max-width: 1000px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 3 - 2 * 2rem / 3);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(3n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(3n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell {
    width: calc(100% / 2 - 1 * 2rem / 2);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(2n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 1 - 0 * 2rem / 1);
  }
  .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(1n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media only screen and (max-width: 800px) {
  .page-section-grid .details-pane {
    width: calc(100% - 5rem);
  }
  .page-section-grid .details-pane .details-slideshow {
    height: 20rem;
  }
  .page-section-grid .details-pane .details-header .cell-close-details {
    width: 3.5rem;
    height: 3.5rem;
    padding: 1rem;
    opacity: 0.3;
    cursor: pointer;
    font-size: 1.5rem;
  }
  .page-section-grid .details-pane .details-header .cell-close-details:hover {
    color: rgba(0, 0, 0, 0.8);
  }
  .page-section-grid .details-pane .details-header .cell-close-details::after {
    content: "✕";
  }
}
@media only screen and (max-width: 800px) and (min-width: 701px) and (max-width: 800px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 4 - 3 * 2rem / 4);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(4n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(4n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell {
    width: calc(100% / 3 - 2 * 2rem / 3);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(3n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 2 - 1 * 2rem / 2);
  }
  .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(2n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media only screen and (max-width: 800px) and (min-width: 501px) and (max-width: 700px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 3 - 2 * 2rem / 3);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(3n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(3n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell {
    width: calc(100% / 2 - 1 * 2rem / 2);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(2n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 1 - 0 * 2rem / 1);
  }
  .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(1n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media only screen and (max-width: 800px) and (min-width: 351px) and (max-width: 500px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 2 - 1 * 2rem / 2);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(2n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(2n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell, .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 1 - 0 * 2rem / 1);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(1n)), .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(1n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media only screen and (max-width: 800px) and (max-width: 350px) {
  .page-section-grid.cell-size-mode-small .grid-cell, .page-section-grid.cell-size-mode-auto .grid-cell {
    width: calc(100% / 1 - 0 * 2rem / 1);
  }
  .page-section-grid.cell-size-mode-small .grid-cell:not(:nth-child(1n)), .page-section-grid.cell-size-mode-auto .grid-cell:not(:nth-child(1n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-small .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-auto .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell, .page-section-grid.cell-size-mode-large .grid-cell {
    width: calc(100% / 1 - 0 * 2rem / 1);
  }
  .page-section-grid.cell-size-mode-medium .grid-cell:not(:nth-child(1n)), .page-section-grid.cell-size-mode-large .grid-cell:not(:nth-child(1n)) {
    margin-right: 2rem;
  }
  .page-section-grid.cell-size-mode-medium .grid-cell .cell-thumbnail, .page-section-grid.cell-size-mode-large .grid-cell .cell-thumbnail {
    min-height: 7rem;
    min-width: 7rem;
  }
}
@media only screen and (max-device-width: 1024px) and (orientation: landscape) and (orientation: landscape) {
  .page-section-grid .details-pane {
    flex-direction: row;
  }
  .page-section-grid .details-pane .constraints-container {
    padding: 1rem;
    padding-left: 0;
    width: 15rem;
    left: 0px;
  }
  .page-section-grid .details-pane .details-slideshow {
    position: absolute;
    width: calc(100% - 15rem);
    height: calc(100% - 4rem);
    right: 0px;
    bottom: 0px;
  }
  .page-section-grid .details-pane .details-notes .details-note {
    width: 11rem;
  }
}