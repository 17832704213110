.page-section-medium {
  overflow: hidden;
  position: relative;
  transition: opacity 0.8s;
}
.page-section-medium .medium-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
}
.page-section-medium .medium-overlay .project-story-title-container {
  margin: auto;
  color: #FFF;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.page-section-medium .medium-image {
  width: 100%;
  height: 100%;
}
.page-section-medium .medium-image img {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .page-section-medium {
    height: auto;
  }
  .page-section-medium .medium-overlay .project-story-title-container {
    padding: 0 1rem;
  }
  .page-section-medium .medium-overlay .project-story-title-container .project-story-title {
    font-size: 7vw;
    letter-spacing: 0.7vw;
    margin-bottom: 1vw;
  }
  .page-section-medium .medium-overlay .project-story-title-container .project-story-location {
    font-size: 4vw;
    letter-spacing: 0.3vw;
  }
  .page-section-medium .medium-image {
    background: none !important;
  }
  .page-section-medium video {
    width: 100%;
  }
}
@media print, (min-width: 801px) {
  .page-section-medium {
    height: 100%;
  }
  .page-section-medium .medium-overlay .project-story-title-container {
    padding: 0 4rem;
  }
  .page-section-medium .medium-overlay .project-story-title-container .project-story-title {
    font-size: 5.5vw;
    letter-spacing: 0.4vw;
    margin-bottom: 2rem;
  }
  .page-section-medium .medium-overlay .project-story-title-container .project-story-location {
    font-size: 3vw;
    letter-spacing: 0.2vw;
  }
  .page-section-medium .medium-image {
    background-size: cover;
    background-position: center;
  }
  .page-section-medium .medium-image img {
    height: 100%;
    opacity: 0;
    position: absolute;
  }
  .page-section-medium.position-top .medium-image {
    background-position: top;
  }
  .page-section-medium.position-bottom .medium-image {
    background-position: bottom;
  }
  .page-section-medium .medium-video {
    width: 100%;
    height: 100%;
  }
  .page-section-medium .medium-video video {
    width: 100%;
    height: 100%;
  }
}