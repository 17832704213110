@import 'styles/index.scss';

.register-employee {
  display: flex;
  height: 100%;

  .register-employee-content-container {
    margin: auto;
    text-align: center;

    .register-employee-message {
      color: rgb(119, 152, 173);
      margin-bottom: 2rem;
    }
    .redirect-message {
      .click-here-link {
        opacity: 0.8;
      }
    }
  }

  @media #{$narrowWindow} {
    width: calc(100% - 2rem);

    .register-employee-message {
      font-size: 1.2rem;
    }
  }

  @media #{$wideWindow} {
    width: calc(100% - 5rem);

    .register-employee-message {
      font-size: 1.8rem;
    }
  }
}
