.app-navigation-list-item {
  user-select: none;
  transition: opacity 0.9s, left 0.4s;
  position: relative;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.app-navigation-list-item.is-active > .list-item-target .list-item-target-text {
  color: rgb(119, 152, 173);
}
.app-navigation-list-item:not(.is-expanded) .list-item-children {
  display: none;
}
.app-navigation-list-item.space-before {
  margin-top: 1.5rem;
}
.app-navigation-list-item.list-item-level-0 .list-item-children {
  border-left: 1px solid rgba(70, 70, 70, 0.4);
}
.app-navigation-list-item:not(.is-visible) {
  opacity: 0;
  left: -1rem;
}
.app-navigation-list-item .list-item-target {
  display: flex;
  cursor: pointer;
}
.app-navigation-list-item .list-item-target .list-item-target-content {
  cursor: pointer;
}
.app-navigation-list-item .list-item-target .list-item-target-content .list-item-target-text {
  transition: opacity 0.4s;
  display: inline-block;
  vertical-align: middle;
}
@media print, (min-device-width: 1025px) {
  .app-navigation-list-item .list-item-target {
    padding: 3px 2rem;
  }
  .app-navigation-list-item .list-item-target:hover {
    opacity: 0.6;
  }
  .app-navigation-list-item .list-item-children.has-children {
    margin: 0.5rem 0rem;
    margin-left: 3rem;
  }
}
@media only screen and (max-device-width: 1024px) {
  .app-navigation-list-item .list-item-target {
    padding: 0.3rem 1.5rem;
    font-size: 1.25rem;
  }
  .app-navigation-list-item .list-item-children.has-children {
    margin: 0.5rem 0rem;
    margin-left: 2rem;
  }
}