@import "lib/styles/import.scss";

.radio-input {
  .radio {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }

    input {
      $diameter: 1rem;

      flex-shrink: 0;
      margin: 1px;
      width: $diameter;
      height: $diameter;
      border-radius: $diameter / 2;
      cursor: pointer;
    }
    label {
      display: inline-block;
      padding: 0;
      padding-left: 1rem;

      &:hover:not(:focus) {
        opacity: $hoverOpacity;
      }
    }
  }
}
