.page-section-text {
  font-size: 1rem;
}
.page-section-text .text-container {
  width: 100%;
  height: 100%;
  display: flex;
  line-height: 1.4;
}
.page-section-text .text-container .text-content {
  white-space: pre-line;
}
.page-section-text .text-container .text-content .page-section-text-item {
  transition: opacity 1s;
}
.page-section-text .text-container .text-content .page-section-text-item:not(.is-visible) {
  opacity: 0;
}
.page-section-text .text-container .text-content .text-item-plain:not(:last-child) {
  margin-bottom: 1em;
}
.page-section-text .text-container .text-content .text-item-heading {
  font-size: 1.2em;
  margin-bottom: 1em;
  font-weight: bold;
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content .text-item-sub-heading {
  color: rgb(119, 152, 173);
  margin-top: 1em;
  margin-bottom: 1em;
}
.page-section-text .text-container .text-content .text-item-larger-text {
  font-size: 110%;
}
.page-section-text .text-container .text-content .text-item-larger-text-space-after {
  font-size: 110%;
  margin-bottom: 1em;
}
.page-section-text .text-container .text-content .text-item-larger-text-space-after.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content .text-item-smaller-text {
  font-size: 75%;
}
.page-section-text .text-container .text-content .text-item-smaller-text.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content .text-item-smaller-text-space-after {
  margin-bottom: 1em;
  font-size: 75%;
}
.page-section-text .text-container .text-content .text-item-smaller-text-space-after.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content .text-item-link {
  display: block;
  text-transform: uppercase;
  line-height: 1.6;
}
.page-section-text .text-container .text-content .text-item-link.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content .text-item-link.blue:hover {
  color: rgb(80, 112, 133);
}
.page-section-text .text-container .text-content .text-item-link:not(.blue) {
  color: rgb(180, 180, 180);
}
.page-section-text .text-container .text-content .text-item-link:not(.blue):hover {
  color: rgb(139, 138, 135);
}
.page-section-text .text-container .text-content .text-item-link.next-page {
  margin-top: 3em;
}
.page-section-text .text-container .text-content .text-item-link.larger-text {
  font-size: 80%;
}
.page-section-text .text-container .text-content a:not(.text-item-link), .page-section-text .text-container .text-content .pseudo-link {
  cursor: pointer;
  text-decoration: underline;
}
.page-section-text .text-container .text-content a.no-underline {
  text-decoration: none;
}
.page-section-text .text-container .text-content a.no-underline.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .text-content a.no-underline.blue:hover {
  color: rgb(80, 112, 133);
}
.page-section-text .text-container .text-content a.no-underline:not(.blue) {
  color: rgb(180, 180, 180);
}
.page-section-text .text-container .text-content a.no-underline:not(.blue):hover {
  color: rgb(139, 138, 135);
}
.page-section-text .text-container .text-content a.phone {
  text-decoration: none;
}
@media only screen and (max-width: 800px) {
  .page-section-text .text-container .text-content {
    padding: 2rem;
  }
}
@media print, (min-width: 801px) {
  .page-section-text .text-container .text-content {
    padding: 2rem;
  }
}
.page-section-text .text-container .quote-content, .page-section-text .text-container .award-content {
  width: 100%;
}
.page-section-text .text-container .quote-content a.blue, .page-section-text .text-container .award-content a.blue {
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .quote-content a.blue:hover, .page-section-text .text-container .award-content a.blue:hover {
  color: rgb(80, 112, 133);
}
.page-section-text .text-container .quote-content .quote-author {
  margin-top: 0.75rem;
  text-align: right;
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .quote-content .quote-author:hover {
  color: rgb(80, 112, 133);
}
.page-section-text .text-container .award-content .award-media {
  height: 5rem;
  margin-bottom: 1rem;
}
.page-section-text .text-container .award-content .award-media img {
  height: 100%;
}
.page-section-text .text-container .award-content .award-title-container {
  margin-bottom: 1rem;
}
.page-section-text .text-container .award-content .award-title-container .award-title {
  margin-top: 0.75rem;
  color: rgb(119, 152, 173);
}
.page-section-text .text-container .award-content .award-title-container .award-title:hover {
  color: rgb(80, 112, 133);
}
@media print, (min-width: 801px) {
  .page-section-text .text-container.align-y-top {
    align-items: flex-start;
  }
  .page-section-text .text-container.align-y-center {
    align-items: center;
  }
  .page-section-text .text-container.align-y-bottom {
    align-items: flex-end;
  }
  .page-section-text .text-container.align-x-left {
    justify-content: flex-start;
  }
  .page-section-text .text-container.align-x-center {
    justify-content: center;
    position: relative;
  }
  .page-section-text .text-container.align-x-right {
    justify-content: flex-end;
  }
}