.constraints {
  display: block;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  padding: 1rem 2rem;
}
.constraints .constraint-title, .constraints .constraint-value, .constraints .constraint-values, .constraints .step-line {
  display: inline-block;
  vertical-align: middle;
}
.constraints .constraint {
  padding: 0.2rem 0;
}
.constraints .constraint .constraint-title {
  text-transform: lowercase;
  width: 1.5rem;
  opacity: 0.5;
  font-size: 1.3rem;
  margin-right: 0.5rem;
}
.constraints .constraint .constraint-values {
  white-space: normal;
  max-width: calc(100% - 2rem);
}
.constraints .constraint .constraint-values .constraint-value {
  font-size: 1rem;
  white-space: nowrap;
  line-height: 1.5;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-min, .constraints .constraint .constraint-values .constraint-value.constraint-value-max, .constraints .constraint .constraint-values .constraint-value.constraint-value-fixed {
  padding: 0.1rem 0.6rem;
  border: 1px solid black;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-min::after, .constraints .constraint .constraint-values .constraint-value.constraint-value-max::after, .constraints .constraint .constraint-values .constraint-value.constraint-value-fixed::after {
  content: '"';
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-step {
  padding-left: 0.5rem;
  text-align: center;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-fixed {
  margin-left: -1px;
  margin-top: -1px;
  min-width: 2.6rem;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-line {
  height: 0;
  text-align: center;
  width: 0.4rem;
  border-bottom: 1px solid black;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-antecedent {
  padding: 0rem 0.3rem;
  text-align: center;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-antecedent.fixed::after {
  content: "";
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-antecedent.fixed:nth-last-child(3)::after {
  content: "or";
  padding: 0rem 0.5rem;
}
.constraints .constraint .constraint-values .constraint-value.constraint-value-antecedent.fixed:last-child {
  display: none;
}