.landing-page {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.landing-page .landing-page-frame {
  border-bottom: 1px solid #e5e5e5;
}
.landing-page .landing-page-frame, .landing-page .slideshow-slide {
  display: flex;
}
.landing-page .landing-page-frame .text-container, .landing-page .landing-page-frame .media-container, .landing-page .slideshow-slide .text-container, .landing-page .slideshow-slide .media-container {
  display: flex;
  position: relative;
}
.landing-page .landing-page-frame .text-container, .landing-page .slideshow-slide .text-container {
  flex-direction: column;
  line-height: 1.4;
  flex: 1;
}
.landing-page .landing-page-frame .text-container .text-item, .landing-page .slideshow-slide .text-container .text-item {
  width: 100%;
}
.landing-page .landing-page-frame .text-container .text-item a, .landing-page .landing-page-frame .text-container .text-item .discuss-a-project, .landing-page .slideshow-slide .text-container .text-item a, .landing-page .slideshow-slide .text-container .text-item .discuss-a-project {
  color: rgb(119, 152, 173);
  cursor: pointer;
}
.landing-page .landing-page-frame .text-container .text-item a:hover, .landing-page .landing-page-frame .text-container .text-item .discuss-a-project:hover, .landing-page .slideshow-slide .text-container .text-item a:hover, .landing-page .slideshow-slide .text-container .text-item .discuss-a-project:hover {
  color: rgb(80, 112, 133);
}
.landing-page .landing-page-frame .text-container .text-item.heading, .landing-page .slideshow-slide .text-container .text-item.heading {
  font-weight: bold;
  color: rgb(119, 152, 173);
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.landing-page .landing-page-frame .text-container.title-text .company-name, .landing-page .landing-page-frame .text-container.title-text .tag-line, .landing-page .landing-page-frame .text-container.title-text .secondary-tag-line, .landing-page .slideshow-slide .text-container.title-text .company-name, .landing-page .slideshow-slide .text-container.title-text .tag-line, .landing-page .slideshow-slide .text-container.title-text .secondary-tag-line {
  opacity: 0;
  animation: fadeInAnimation 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.landing-page .landing-page-frame .text-container.title-text .company-name, .landing-page .landing-page-frame .text-container.title-text .tag-line, .landing-page .slideshow-slide .text-container.title-text .company-name, .landing-page .slideshow-slide .text-container.title-text .tag-line {
  animation-delay: 0.25s;
}
.landing-page .landing-page-frame .text-container.title-text .secondary-tag-line, .landing-page .slideshow-slide .text-container.title-text .secondary-tag-line {
  animation-delay: 1s;
}
.landing-page .landing-page-frame .text-container.title-text .company-name span:nth-child(2), .landing-page .slideshow-slide .text-container.title-text .company-name span:nth-child(2) {
  opacity: 0.7;
}
.landing-page .landing-page-frame .text-container.title-text .tag-line, .landing-page .slideshow-slide .text-container.title-text .tag-line {
  font-weight: bold;
}
.landing-page .landing-page-frame .text-container.title-text .secondary-tag-line, .landing-page .slideshow-slide .text-container.title-text .secondary-tag-line {
  font-weight: bold;
}
.landing-page .landing-page-frame .media-container .image-container, .landing-page .slideshow-slide .media-container .image-container {
  height: 100%;
  width: 100%;
}
.landing-page .landing-page-frame .media-container .image-container img, .landing-page .slideshow-slide .media-container .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.landing-page .scroll-arrow-position-container {
  position: fixed;
  display: flex;
}
.landing-page .scroll-arrow-position-container .left-container {
  display: flex;
  justify-content: center;
}
.landing-page .scroll-arrow-position-container .left-container .scroll-arrow-container {
  height: 2rem;
  width: 6rem;
  display: flex;
  transition: visibility 0s, opacity 1s;
}
.landing-page .scroll-arrow-position-container .left-container .scroll-arrow-container.hidden {
  visibility: hidden;
  opacity: 0;
  height: 0;
}
.landing-page .scroll-arrow-position-container .left-container .scroll-arrow-container.visible {
  visibility: visible;
  opacity: 1;
}
.landing-page .scroll-arrow-position-container .left-container .scroll-arrow-container .arrow {
  border: solid #bbb;
  border-width: 0 2px 2px 0;
  width: 0;
  height: 0;
  padding: 5px;
  margin: auto;
  transform: rotate(45deg);
  translate: 0 -2.5px;
}
@media only screen and (max-width: 800px) {
  .landing-page .landing-page-frame {
    height: 100vh;
    height: 100lvh;
    min-height: 100vw;
  }
  .landing-page .landing-page-frame, .landing-page .slideshow-slide {
    flex-direction: column-reverse;
  }
  .landing-page .landing-page-frame:not(:last-child), .landing-page .slideshow-slide:not(:last-child) {
    padding-bottom: 3rem;
  }
  .landing-page .landing-page-frame .text-container, .landing-page .slideshow-slide .text-container {
    padding: 10vw;
  }
  .landing-page .landing-page-frame .text-container.align-center, .landing-page .slideshow-slide .text-container.align-center {
    align-items: center;
  }
  .landing-page .landing-page-frame .text-container.title-text .company-name, .landing-page .slideshow-slide .text-container.title-text .company-name {
    font-size: 7.5vw;
    letter-spacing: 1.8vw;
    margin-bottom: 3vw;
  }
  .landing-page .landing-page-frame .text-container.title-text .tag-line, .landing-page .slideshow-slide .text-container.title-text .tag-line {
    font-size: 3.5vw;
    margin-bottom: 9vw;
    letter-spacing: 1vw;
  }
  .landing-page .landing-page-frame .text-container.title-text .secondary-tag-line, .landing-page .slideshow-slide .text-container.title-text .secondary-tag-line {
    font-size: 2vw;
    letter-spacing: 0.5vw;
    text-align: center;
  }
  .landing-page .landing-page-frame .text-container .text-item, .landing-page .slideshow-slide .text-container .text-item {
    max-width: 60vw;
    font-size: 3.75vw;
  }
  .landing-page .landing-page-frame .text-container .text-item.heading, .landing-page .slideshow-slide .text-container .text-item.heading {
    font-size: 5vw;
  }
  .landing-page .landing-page-frame .text-container .text-item:not(:last-child), .landing-page .slideshow-slide .text-container .text-item:not(:last-child) {
    margin-bottom: 1.5vw;
  }
  .landing-page .landing-page-frame .media-container, .landing-page .slideshow-slide .media-container {
    height: 50vh;
    height: 50lvh;
  }
  .landing-page .scroll-arrow-position-container {
    width: 100%;
    bottom: 5vw;
  }
  .landing-page .scroll-arrow-position-container .left-container {
    flex: 1;
  }
}
@media print, (min-width: 801px) {
  .landing-page .landing-page-frame {
    height: 100vh;
    height: 100lvh;
  }
  .landing-page .landing-page-frame, .landing-page .slideshow-slide {
    flex-direction: row;
  }
  .landing-page .landing-page-frame .text-container, .landing-page .slideshow-slide .text-container {
    min-width: 50vh;
    min-width: 50lvh;
    padding: 3vw;
    align-items: center;
    justify-content: center;
  }
  .landing-page .landing-page-frame .text-container.title-text .company-name, .landing-page .slideshow-slide .text-container.title-text .company-name {
    font-size: 2.25vw;
    margin-bottom: 1rem;
    letter-spacing: 0.75vw;
  }
  .landing-page .landing-page-frame .text-container.title-text .tag-line, .landing-page .slideshow-slide .text-container.title-text .tag-line {
    font-size: 1vw;
    margin-bottom: 15%;
    letter-spacing: 0.5vw;
  }
  .landing-page .landing-page-frame .text-container.title-text .secondary-tag-line, .landing-page .slideshow-slide .text-container.title-text .secondary-tag-line {
    font-size: 0.75vw;
    letter-spacing: 0.25vw;
  }
  .landing-page .landing-page-frame .text-container .text-item, .landing-page .slideshow-slide .text-container .text-item {
    max-width: 25rem;
    font-size: calc(8px + 0.5vw);
  }
  .landing-page .landing-page-frame .text-container .text-item.heading, .landing-page .slideshow-slide .text-container .text-item.heading {
    font-size: calc(8px + 0.75vw);
  }
  .landing-page .landing-page-frame .text-container .text-item:not(:last-child), .landing-page .slideshow-slide .text-container .text-item:not(:last-child) {
    margin-bottom: 0.5vw;
  }
  .landing-page .landing-page-frame .media-container, .landing-page .slideshow-slide .media-container {
    width: 100vh;
    width: 100lvh;
  }
  .landing-page .scroll-arrow-position-container {
    width: calc(100% - 5rem);
    bottom: 5rem;
    pointer-events: none;
  }
  .landing-page .scroll-arrow-position-container .left-container {
    min-width: 50vh;
    min-width: 50lvh;
    padding: 0 3vw;
    flex: 1;
    pointer-events: all;
  }
  .landing-page .scroll-arrow-position-container .right-container {
    width: 100vh;
    width: 100lvh;
  }
  .landing-page .scroll-arrow-position-container .scroll-arrow-container {
    cursor: pointer;
    left: calc(25% + 5rem);
  }
  .landing-page .scroll-arrow-position-container .scroll-arrow-container:hover .arrow {
    border-color: #777;
  }
}